import styled from 'styled-components'

export const Dropdown = styled.div`
  & > button {
    &:hover {
      color: ${({ theme }) => theme.colorPrimary};
    }
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colorLightGrey};
  }
  .dropdown-item {
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colorLightGrey};

    &.active {
      background-color: unset;
    }
    &:hover {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: unset;
  }
`

export const DropdownMenu = styled.ul`
  background-color: ${(props) => props.theme.greyBorder};

  & > .dropdown-item {
    color: white;
    font-size: 15px;
    padding: 5px;
    &:hover {
      color: ${(props) => props.theme.colorPrimary};
      background-color: unset;
      cursor: pointer;
    }
  }
`
