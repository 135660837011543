import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

function CustomScrollbars({ style, children }) {
  return (
    <Scrollbars
      renderTrackVertical={(props) => <div {...props} className='track-vertical' />}
      renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
      renderView={(props) => <div {...props} className='view' />}
      style={style}
    >
      {children}
    </Scrollbars>
  )
}

export default CustomScrollbars
