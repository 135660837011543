import { ACTION_TYPES } from './tokenListSlice'

export const setTokens = (tokens) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.set,
    payload: tokens,
  })
}

export const init = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.init,
    payload: [],
  })
}

export const add = (_token) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.add,
    payload: _token,
  })
}

export const setItem = (_index, tokens) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.setItem,
    payload: {
      index: _index,
      tokens: tokens,
    },
  })
}

export const updateItem = (_index, item) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.updateItem,
    payload: {
      index: _index,
      item: item,
    },
  })
}

export const setItems = (items) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.setItems,
    payload: items,
  })
}

export const setUsePoolTokens = (state) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.setUsePoolTokens,
    payload: state,
  })
}

// export const setTokens = (_pairs) => (dispatch) => {
//   dispatch({
//     type: ACTION_TYPES.setTokens,
//     payload: _pairs
//   })
// }
