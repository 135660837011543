import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SwapLinkbx } from '../styled'
import YourLiquidity from './YourLiquidity'
import AddLiquidity from './AddLiquidity'
import ManageLiquidity from './ManageLiquidity'
import RemoveLiquidity from './RemoveLiquidity'
import SelectTokenModal from 'Component/popup/SelectToken/index'
import { useLiquidity, useWallet } from '../../hooks'
import Tabs from '../shared/Tabs'
import { AppGlobalContext } from '../../contexts'
import { ZERO_ADDRESS } from '../../constants/addresses'
import { defaultToken } from '../../constants/index'
import { numberFormat } from '../../utils/new'
import { useSelector } from 'react-redux'

const TokenSelectType = {
  tokenA: 1,
  tokenB: 2,
}

const defaultTab = 2

function LiquidityIndex() {
  const [showSelectToken, setShowSelectToken] = useState(false)
  const [tabIndex, setTabIndex] = useState(defaultTab)
  const [selectedType, setSelectedType] = useState(TokenSelectType.tokenA)
  const { account } = useWallet()
  const { usePoolTokens } = useSelector(state => state.tokenList)

  const {
    dex: { factory: factoryAddress, router: routerAddress },
    network,
  } = useContext(AppGlobalContext) // to amount
  const {
    tokenA,
    tokenB,
    amountA,
    amountB,
    approved,
    balanceA,
    balanceB,
    lpTokenValue,
    totalLiqValues,
    tokenRatio,
    impact,
    removeLiqValue,
    removeLiquidityPriceB,
    removeAllowance,
    pairAddress,
    fromAmount,
    toAmount,
    setTokenA,
    setTokenB,
    setAmountA,
    setAmountB,
    addLiquidity,
    approveLiquidity,
    useMaxAmount,
    setRemoveLiqValue,
    approveCall,
    removeLiquidity,
  } = useLiquidity({ W_TOKEN_ADDRESS: network.W_TOKEN_ADDRESS, account, factoryAddress, routerAddress: routerAddress, options: {} })

  const onTokenChainHandler = useCallback(
    (_token) => {
      if (selectedType === TokenSelectType.tokenA) {
        setTokenA({
          name: _token.name,
          address: _token.values,
          image: _token.image,
        })
      } else {
        setTokenB({
          name: _token.name,
          address: _token.values,
          image: _token.image,
        })
      }
      setShowSelectToken(false)
    },
    [selectedType, setTokenA, setTokenB]
  )
  const toggleTabs = useCallback((_tabIndex) => {
    setTabIndex(_tabIndex)
  }, [])

  const onClickSelectToken = useCallback((type) => {
    setSelectedType(type)
    setShowSelectToken(true)
  }, [])

  const handleDragore = useCallback(
    (value) => {
      setRemoveLiqValue(value)
    },
    [setRemoveLiqValue]
  )
  const handleOnClickClose = useCallback(() => {
    setTabIndex(defaultTab)
  }, [])

  const yourLiquidityElt = useMemo(() => {
    return (
      <YourLiquidity
        tokenA={tokenA}
        tokenB={tokenB}
        liqValues={totalLiqValues}
        pairAddress={pairAddress}
        onClickAdd={() => toggleTabs(2)}
        onClickRemove={() => toggleTabs(4)}
        onClickClose={handleOnClickClose}
        onClickSelectTokenA={() => onClickSelectToken(TokenSelectType.tokenA)}
        onClickSelectTokenB={() => onClickSelectToken(TokenSelectType.tokenB)}
      />
    )
  }, [tokenA, tokenB, pairAddress, totalLiqValues, toggleTabs, handleOnClickClose, onClickSelectToken])

  useEffect(() => {
    setTokenA({
      name: network.MAIN_TOKEN_NAME,
      address: ZERO_ADDRESS,
      image: network.MAIN_TOKEN_ICON,
    })
    setTokenB(defaultToken)
  }, [network, setTokenA, setTokenB])

  return (
    <>
      <div className='w-100'>
        <Tabs activedTabIndex={tabIndex}>
          <div tabIndex={1}>{yourLiquidityElt}</div>
          <div tabIndex={2}>
            <AddLiquidity
              tokenA={tokenA}
              tokenB={tokenB}
              amountA={amountA}
              amountB={amountB}
              balanceA={balanceA}
              balanceB={balanceB}
              lpTokenValue={lpTokenValue}
              totalLiqValues={totalLiqValues}
              tokenRatio={tokenRatio}
              impact={impact}
              pairAddress={pairAddress}
              onSelectTokenB={() => onClickSelectToken(TokenSelectType.tokenB)}
              onSelectTokenA={() => onClickSelectToken(TokenSelectType.tokenA)}
              onChangeInputAmount={(e) => {
                const value = numberFormat(e.target.value)
                setAmountA(value)
              }}
              onChangeToAmount={(e) => {
                const value = numberFormat(e.target.value)
                setAmountB(value)
              }}
              approved={approved}
              onClickAdd={addLiquidity}
              onClickApprove={approveLiquidity}
              onClickMax={useMaxAmount}
            />
          </div>
          <div tabIndex={3}>
            <ManageLiquidity onClickAddLiquidity={() => toggleTabs(2)} onClickClose={() => toggleTabs(defaultTab)} />
          </div>
          <div tabIndex={4}>
            <RemoveLiquidity
              lpToken={lpTokenValue}
              toPrice={removeLiquidityPriceB}
              tokenB={tokenB}
              tokenA={tokenA}
              balanceA={balanceA}
              fromAmount={fromAmount}
              toAmount={toAmount}
              removeAllowance={removeAllowance}
              removeLiqValue={removeLiqValue}
              onClickApproveLiquidity={approveCall}
              onClickRemoveLiquidity={removeLiquidity}
              onClickSelectTokenA={() => onClickSelectToken(TokenSelectType.tokenA)}
              onClickSelectTokenB={() => onClickSelectToken(TokenSelectType.tokenB)}
              onClickClose={() => toggleTabs(defaultTab)}
              handleDragore={handleDragore}
            />
          </div>
        </Tabs>
        <SwapLinkbx>
          <a href='#A' onClick={() => toggleTabs(3)}>
            Manage liquidity
          </a>
          |
          <a href='#A' onClick={() => toggleTabs(1)}>
            <span>Add/remove liquidity</span>
          </a>
          {/* | <a >Migrate liquidity</a>  */}
        </SwapLinkbx>
      </div>
      {/* <SwapBTN01 onClick={()=>{this.removeliquidity()}}>removeliquidity</SwapBTN01> */}
      <SelectTokenModal isOpen={showSelectToken} disabled={[tokenA.name, tokenB.name]} onTokenChainHandler={onTokenChainHandler} dismiss={() => setShowSelectToken(false)} onlyAvailableTokens={usePoolTokens} />
    </>
  )
}

export default LiquidityIndex
