import { useCallback, useEffect } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { LOCAL_STORAGE_ITEM_NAMES } from '../constants/localStorage'
import { BinanceWalletNetworkIds, Connectors, Connect_TYPE } from '../Connectors'
import { CHAINS_HEX } from '../constants'
import Web3Utils from 'web3-utils'
import { useAppSnackbar } from './useAppSnackbar'

export const useWallet = () => {
  const { active, activate, error, account, library, deactivate, ...web3React } = useWeb3React()
  const [openSnackbar] = useAppSnackbar()

  const connectWallet = useCallback(
    async (type) => {
      let connector = Connectors[type]
      try {
        await activate(connector)
        localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE, type)
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)
        console.error(error)
      }
    },
    [activate]
  )

  const switchBinanceWalletNetwork = useCallback(
    /**
     *
     * @param {String | Number} chainId @description Chain id in hex
     * @returns {Promise}
     */
    async (chainId) => {
      await window.BinanceChain?.switchNetwork(BinanceWalletNetworkIds[parseInt(chainId)])
    },
    []
  )
  const switchMetaMaskWalletNetwork = useCallback(
    /**
     *
     * @param {String | Number} chain @description Chain id in hex
     * @returns {Promise}
     */
    async (chain) => {
      await library.provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3Utils.toHex(chain) }],
      })
    },
    [library?.provider]
  )

  const switchNetwork = useCallback(
    /**
     *
     * @param {String | Number} chain
     */
    async (chain) => {
      const connectedType = parseInt(localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE), 10)
      let chainId = '0x00'
      if (typeof chain === 'string') {
        chainId = CHAINS_HEX[chain]
      } else {
        chainId = Web3Utils.toHex(chain)
      }

      switch (connectedType) {
        case Connect_TYPE.BINANCE_CHAIN: // works for binance wallet
          await switchBinanceWalletNetwork(chainId)
          break

        default: // works for metamask and math walelt
          await switchMetaMaskWalletNetwork(chainId)
          break
      }
    },
    [switchBinanceWalletNetwork, switchMetaMaskWalletNetwork]
  )

  /**
   * @param type ConnectorType
   */
  const handleErrors = useCallback(
    async (error, type) => {
      if (error instanceof UnsupportedChainIdError) {
        try {
          await switchNetwork(56) // switch to binance main network.
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            openSnackbar('Please add the network to your wallet')
          }
        }
      } else {
        console.log('Unhandled Error', error)
      }
    },
    [openSnackbar, switchNetwork]
  )

  const isConnected = useCallback(async () => {
    const savedWalletType = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)

    if (savedWalletType) {
      let connector = Connectors[savedWalletType]
      try {
        if (typeof connector.isAuthorized === 'function') {
          let isAuthorized = await connector.isAuthorized()
          if (isAuthorized) {
            await connectWallet(savedWalletType)
          }
        }
      } catch (err) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)
        handleErrors(err)
        console.log(err)
      }
    }
  }, [connectWallet, handleErrors])

  const disconnectWallet = useCallback(() => {
    deactivate()
    localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)
  }, [deactivate])

  useEffect(() => {
    if (error) {
      handleErrors(error)
    }
  }, [error, handleErrors])

  useEffect(() => {
    if (account) {
      localStorage.setItem('account', account) // saving it for old functions
    } else {
      localStorage.removeItem('account') // removing it for old functions
    }
  }, [account])

  return {
    active,
    error,
    account,
    library,
    ...web3React,
    connectWallet,
    switchNetwork,
    activate,
    isConnected,
    disconnectWallet,
  }
}
