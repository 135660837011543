import { AiFillWarning } from 'react-icons/ai'
import styled, { keyframes } from 'styled-components'
import Media from '../../Theme/media-breackpoint'
import { FlexDiv } from '../Swap/styled'
import Cross from '../../Assets/images/close-icon.png'
import defaultIcon from 'Assets/images/defaultIcon.svg'

export const IconsCenterbox = styled.div`
  display: flex;
  position: relative;
  padding: 6px;
  justify-content: center;

  ${Media.xl} {
    padding: 6px;
  }
  ${Media.md} {
    display: block;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    max-width: 200px;
    color: #8e9195;
    white-space: nowrap;
    margin-top: 13px;
    margin-right: 10px;
    ${Media.xl} {
      position: initial;
    }
    ${Media.md} {
      display: block;
      margin: 0 auto 18px;
      text-align: center;
    }
  }
  .icon-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      width: 34px;
      height: 34px;
      background-color: #16191e;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 4px 9px;
      :hover {
        background-color: #000;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      ${Media.md} {
        margin: 5px;
      }
    }
    ${Media.md} {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`

const pulse = ({ rgbColor }) => keyframes`
  0% {
    box-shadow: 0 0 0 0 rgb(${rgbColor.join(' ')} / 40%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(${rgbColor.join(' ')} / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(${rgbColor.join(' ')} / 0%);
  }
`

export const PulseAnimation = styled.div`
  cursor: pointer;
  z-index: 1;

  ${({ rgbColor = [255, 255, 255] }) => ({
    backgroundColor: `${rgbColor.join(' ')}` || '#8be05a',
  })}
  animation: ${pulse} 2s infinite;
`

const SWrongNetworkBtn = styled.button`
  align-items: center;
  border-color: ${(props) => props.theme.colorRed};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: center;
  left: 0px;
  width: ${(props) => props.size || '40px'} !important;
  height: ${(props) => props.size || '40px'} !important;
  z-index: 102;

  & > svg {
    color: ${(props) => props.theme.colorRed};
  }
`

export function WrongNetworkBtn({ iconSize = 25, text, ...props }) {
  return (
    <FlexDiv className='flex-wrap-nowrap'>
      <SWrongNetworkBtn className='mr-5' {...props}>
        <AiFillWarning size={iconSize} />
      </SWrongNetworkBtn>
      {text}
    </FlexDiv>
  )
}

export const CloseBtn = ({ ...props }) => {
  return (
    <div className='close-icon' {...props}>
      <button>
        <img src={Cross} alt='' width='22' height='25' />
      </button>
    </div>
  )
}

const TokenImage = styled.img`
  &.token-icon {
    height: 20px;
    width: 20px;
    margin-right: 7.5px;
    background-color: white;
    border-radius: 5000px;
  }
`

export const TokenIcon = ({ src, alt, ...props }) => {
  return (
    <TokenImage
      src={src || defaultIcon}
      onError={(e) => {
        e.target.onerror = null
        e.target.src = defaultIcon
      }}
      alt={alt || ''}
      {...props}
    />
  )
}
