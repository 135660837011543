import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Media from './../Theme/media-breackpoint'
import { getDMCPrice } from '../utils/new'

const Footer = ({ location, hideTokenLogo = false, footerRef, ...props }) => {
  const [dmcPrice, setDmcPrice] = useState(0)

  const fetchAndSetDmcPrice = useCallback(async () => {
    const dmcValue = await getDMCPrice()
    setDmcPrice(+dmcValue)
  }, [])

  useEffect(() => {
    fetchAndSetDmcPrice()
  }, [fetchAndSetDmcPrice])

  useEffect(() => {
    let timer = setInterval(() => {
      fetchAndSetDmcPrice()
    }, 1000 * 60)

    return () => {
      clearInterval(timer)
    }
  }, [fetchAndSetDmcPrice])

  return (
    <div className='app-footer' ref={footerRef}>
      <FooterMain>
        <FooterLeft className=''>
          <img src='/images/DEREX-footer.png' alt='' className='doodle' />
          <div className='footer-detail'>
            {/* <img src={Icon6} alt='' /> */}
            <ValueText>${parseFloat(dmcPrice).toFixed(6)}</ValueText>
            {/* it is related with hook useBuyNowTokens on Dynamic. */}
            {/* Check it if you want to change or update. */}
            <a target='_blank' href={'https://app.derex.exchange/swap?chain=56&tokenA=0x0000000000000000000000000000000000000000&tokenB=0x9b707e2a6a917b1b26de80bf17b33cc88e6cb1db'} rel='noreferrer'>
              BUY NOW
            </a>
          </div>
        </FooterLeft>
        <FooterMiddle>
          <FooterMiddleUpper>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSfOPWfFGLLHL6zy-JJ8XTSeMMHn2SQpONZ0KeWtO60Iv3t0Bw/viewform' target='_blank' rel='noreferrer'>
              Apply for Licensing
            </a>
            <span></span>
            <a href='https://qwantum-finance-labs.gitbook.io/derex-exchange/overview/derex-exchange' target='_blank' rel='noreferrer'>
              White Paper
            </a>
            <span></span>
            <a
              href='https://app.derex.exchange/reimbursement'
              tabIndex={3}
              className={`${location.pathname === '#' ? 'active' : ''}`}
              target='_blank'
              rel='noreferrer'
            >
              100% gas and fees reimbursement
            </a>
          </FooterMiddleUpper>
          <FooterMiddleLower>
            <p>Powered by Qwantun Finance:</p>
            <a href='https://smartswap.exchange/' target='_blank' rel='noreferrer'>
              SmartSwap.exchange
            </a>
            <span></span>
            <a href='https://www.jointer.io/' target='_blank' rel='noreferrer'>
              Jointer.io
            </a>
            <span></span>
            <a href='https://nft.mx' target='_blank' rel='noreferrer'>
              NFT.mx
            </a>
            <span></span>
            <a href='https://pdo.finance/#/list' target='_blank' rel='noreferrer'>
              PDO.finance
            </a>
            <span></span>
            <a href='https://degenswap.io/' target='_blank' rel='noreferrer'>
              DegenSwap.io
            </a>
            <span></span>
            <a href='https://freez.finance/' target='_blank' rel='noreferrer'>
              Freez.Finance
            </a>
            <span></span>
            <a href='https://cbdc.science/' target='_blank' rel='noreferrer'>
              CBDC.science
            </a>
          </FooterMiddleLower>
        </FooterMiddle>
        <FooterRight className='z-index-1'>
          <p className='fs-md mb-0'>Powered by</p>
          <img src='/images/qonetum-footer-logo.png' alt='qonetum logo' />
        </FooterRight>
      </FooterMain>
    </div>
  )
}

export default withRouter(Footer)

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const FooterMain = styled(FlexDiv)`
  background-color: #000;
  padding: 9px 0px;
  justify-content: space-between;
  color: ${({ theme }) => theme.colorLightGrey};
`

const FooterLeft = styled.div`
  position: relative;
  top: 40px;
  font-family: 'Lato', sans-serif;
  z-index: 11;

  .doodle {
    position: absolute;
    left: 20px;
    top: -68px;
    ${Media.sm} {
      width: 75px;
      top: -70px;
    }
  }
  .footer-detail {
    display: flex;
    align-items: center;
    padding-left: 120px;
    img {
      margin-right: 15px;
      width: 23px;
    }
  }
  a {
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colorLightGrey};
    display: flex;
    align-items: center;
    svg {
      margin-left: 15px;
    }
    :hover {
      color: ${(props) => props.theme.colorPrimaryHover};
    }
  }
  ${Media.xxl} {
    z-index: 9;
    width: 100%;

    .footer-detail {
      justify-content: center;
      margin-top: -30px;
      margin-bottom: 55px;
      padding-left: 0;
    }
  }
`

const ValueText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
`

const FooterMiddle = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 20px;
  z-index: 10;

  ${Media.xl} {
    margin-right: 20px;
    z-index: 10;
  }
  ${Media.lg} {
    width: 100%;
    margin: 0px 15px;
  }
`

const FooterMiddleUpper = styled(FlexDiv)`
  margin-bottom: 20px;
  .active {
    color: #8be05a;
  }
  a {
    font-size: 16px;
    font-family: 'Kanit', sans-serif;
    font-weight: 700;
    color: ${({ theme }) => theme.colorLightGrey};
    :hover {
      color: ${(props) => props.theme.colorPrimary};
    }
    ${Media.xs} {
      display: block;
      line-height: 24px;
      text-align: center;
    }
  }
  span {
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.bodybg06};
    margin: 0px 30px;
    ${Media.xs} {
      display: none;
    }
  }
  ${Media.xs} {
    display: block;
  }
`

const FooterMiddleLower = styled(FlexDiv)`
  font-weight: 500;
  font-size: 12px;

  p {
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.colorLightGrey};
    margin: 0px 10px 0px 0px;
    ${Media.xs} {
      text-align: center;
    }
  }
  a {
    color: ${({ theme }) => theme.colorLightGrey};
    :hover {
      color: ${(props) => props.theme.colorPrimary};
    }
    ${Media.xs} {
      display: block;
      line-height: 24px;
      text-align: center;
    }
  }
  span {
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.bodybg06};
    margin: 0px 10px;
    ${Media.xs} {
      display: none;
    }
  }
  ${Media.lg} {
    justify-content: center;
  }
  ${Media.xs} {
    display: block;
  }
`

const FooterRight = styled(FlexDiv)`
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding-right: 20px;
  width: 100%;

  ${Media.lg} {
  }

  ${Media.xl} {
    padding-right: 0px;
    position: relative;
    margin-top: 10px;
    width: 100%;
    align-items: center;
  }
`
