import { store } from './redux/store'
import { Provider as ReduxProvider } from 'react-redux'
import { theme } from './Theme/theme'
import { ThemeProvider } from 'styled-components'
import SnackbarProvider from 'react-simple-snackbar'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { Web3Provider } from '@ethersproject/providers'
import { ApolloProvider } from 'react-apollo'
import { createGraphQLClient } from './services/thegraph'
import DexesData from '../public/json/dexes.json'

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}
const Web3ReactProviderReloaded = createWeb3ReactRoot('anotherOne')
const client = createGraphQLClient(DexesData.dexes[0].theGraphUri) // temp graphql Client
const selectedTheme = theme(true)

function Providers({ children }) {
  return (
    <Router>
      <ThemeProvider theme={selectedTheme}>
        <ApolloProvider client={client}>
          <SnackbarProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <Web3ReactProviderReloaded getLibrary={getLibrary}>
                <ReduxProvider store={store}>{children}</ReduxProvider>
              </Web3ReactProviderReloaded>
            </Web3ReactProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </Router>
  )
}

export default Providers
