import { InjectedConnector as Injected } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { SUPPORTED_CHAIN_IDS, INFRA_PROJECT_ID } from './constants/index'

export const BinanceWalletNetworkIds = {
  1: 'eth',
  56: 'bsc-mainnet',
  97: 'bsc-testnet',
}

export const Connect_TYPE = {
  BINANCE_CHAIN: 1,
  METAMASK: 2,
  TRUST_WALLET: 3,
  MATH_WALLET: 4,
}

// for metamask and math wallet
const injected = new Injected({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

// coinbasewallet connector
// const CoinbaseWalletConnector = new WalletLinkConnector({
//   url: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
//   appName: '',
//   supportedChainIds: SUPPORTED_CHAIN_IDS,
// })

const WalletConnecter = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

const bscConnector = new BscConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const Connectors = {
  [Connect_TYPE.BINANCE_CHAIN]: bscConnector,
  [Connect_TYPE.METAMASK]: injected,
  [Connect_TYPE.TRUST_WALLET]: WalletConnecter,
  [Connect_TYPE.MATH_WALLET]: injected,
}
