import styled from 'styled-components'

export const AppButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorWhite};
  border: none;
  background-color: ${(props) => props.theme.colorGrey};
  font-family: 'Kanit', sans-serif;
  font-weight: 700;
  font-size: 21px;
  width: 100%;
  padding: 12px 12px;
  border-radius: 10px;

  :hover {
    background-color: ${(props) => props.theme.colorGrey6};
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.bodybg013} !important;
    :hover {
      background-color: ${(props) => props.theme.colorGrey} !important;
    }
  }
  &.primary {
    background-color: ${(props) => props.theme.colorPrimary};
    :hover {
      background-color: ${(props) => props.theme.colorPrimary01};
    }
  }
  &.black {
    background-color: ${(props) => props.theme.bodybg03};
    :hover {
      background-color: ${(props) => props.theme.bodybg04};
    }
  }
  &.darkGray {
    // background-color: ${(props) => props.theme.colorGrey};
    border: 2px solid ${(props) => props.theme.bodybg013};

    :hover {
      background-color: #ababab;
    }
  }
  &.darkRed {
    background-color: red;
    :hover {
      background-color: red;
    }
  }
  &.orangBack, &.OrangeBTN {
    background-color: #8be05a;
    :hover {
      background-color: #a0ef72;
    }
  }
  &.outline {
    &-primary {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorPrimary};
      box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.colorPrimary};
        color: white;
      }
    }
    &-gray {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
    &-danger {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorRed};
      box-shadow: ${(props) => props.theme.colorRed} 0px 0px 10px 1px;
      color: ${(props) => props.theme.colorRed};

      &:hover {
        background-color: ${(props) => props.theme.colorRed};
        color: white;
      }
    }
    &-disabled {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
  }
`
