import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

function AppToolTip() {
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return <ReactTooltip effect='solid' type='light' className='myTip' />
}

export default AppToolTip
