import { LOCAL_STORAGE_ITEM_NAMES } from 'constants/localStorage'

const initialState = {
  customTokens: [],
}

export const ACTION_TYPES = {
  set: 'CUSTOM_TOKENS/SET',
  remove: 'CUSTOM_TOKENS/remove',
  add: 'CUSTOM_TOKENS/add',
}

function saveToLocalStorage(tokens) {
  if (Array.isArray(tokens)) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.CUSTOM_TOKENS, JSON.stringify(tokens))
  } else {
    throw new Error('tokens should be an Array')
  }
}

function getFromLocalStorage() {
  const localCustom = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.CUSTOM_TOKENS)

  if (!localCustom) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.CUSTOM_TOKENS, JSON.stringify([]))
    return []
  } else {
    let localTokens = JSON.parse(localCustom)
    return localTokens
  }
}

export default function globalReducer(state = initialState, action) {
  let localTokens, address, index
  switch (action.type) {
    case ACTION_TYPES.set:
      saveToLocalStorage(action.payload)
      return { ...state, customTokens: action.payload }
    case ACTION_TYPES.remove:
      address = action.payload
      localTokens = state.customTokens.slice()
      index = localTokens.findIndex((el) => el.address === address)
      localTokens.splice(index, 1)
      saveToLocalStorage(localTokens)
      return { ...state, customTokens: localTokens }
    case ACTION_TYPES.add:
      localTokens = state.customTokens.concat(action.payload)
      return { ...state, customTokens: localTokens }
    default:
      localTokens = getFromLocalStorage()
      return { ...state, customTokens: localTokens }
  }
}
