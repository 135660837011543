import styled from 'styled-components'
import Media from '../Theme/media-breackpoint'
import Xbtn from '../Assets/images/closeBTN.png'
import { AppButton } from './shared/Buttons'

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
export const ArrowDownIconWrapper = styled(FlexDiv)`
  color: ${(props) => props.theme.bodybg08};
`

export const Title01 = styled.div`
  font: 400 16px/25px 'Press Start 2P', arial;
  color: #8e9195;
  margin: 0 0 15px 0;
`

export const TimerBox = styled.div`
  font: 400 50px/60px 'Press Start 2P', arial;
  color: #8ae059;
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    display: inline-block;
    background-color: #16191e;
    padding: 12px 8px;
    margin: 0 3px;
    border-radius: 10px;
    border: 2px solid #5a5e67;
    text-indent: 6px;
  }
`

export const SwapMbox01 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 0 0;
`
export const SwapSbox01 = styled(FlexDiv)`
  width: 33.33%;
  justify-content: flex-start;
  &.rightAlign {
    justify-content: flex-end;
  }
  ${Media.md} {
    width: 100%;
  }
`

export const SwapSbox02 = styled(FlexDiv)`
  width: 33.33%;
  padding: 15px;
  flex-direction: column;
`

export const SwapSSbox01 = styled.div`
  // margin: 10px 10px 0 10px;
  width: 100%;
  max-width: 430px;
  background-color: #16191e;
  border: 2px solid #000;
  min-height: 430px;
  padding: 18px;
  border-radius: 10px;
  &.active {
    // border-color: #8ae059;
    // -webkit-box-shadow: 1px 1px 25px 1px rgb(139 224 90 / 40%);
    // box-shadow: 1px 1px 25px 1px rgb(139 224 90 / 40%);
  }
  ${Media.md} {
    max-width: 100%;
  }
  .right-iconwraper .close-icon {
  }
`

export const SwapTitle01 = styled(FlexDiv)`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 24px;
  position: relative;

  &.smTitle {
    font: 700 14px/25px 'IBM Plex Mono', arial;
    justify-content: flex-start;
    width: 100%;
    span {
      text-transform: none !important;
      position: relative;
      padding-right: 15px;
    }
  }

  img {
    margin: 0 16px 0 0;
  }
  .helpIco {
    position: absolute;
    right: 0px;
  }
  .close-icon {
    position: absolute;
    right: -20px;
    left: auto;
    top: -8px;
  }
`

export const SwapInputTitle = styled(FlexDiv)`
  justify-content: space-between;
  span {
    color: #8e9195;
    font-weight: 700;
    font-size: 14px;
  }
`

export const SwapInputMbx = styled(FlexDiv)`
  border: 2px solid #545861;
  border-radius: 10px;
  min-height: 90px;
  padding: 12px 14px;
`
export const SSbx01 = styled(FlexDiv)`
  margin: 0 auto 0 0;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: auto;
  // max-width: 40%;
  input {
    opacity: 0.8;
    background-color: transparent;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    height: 25px;
    color: ${(props) => props.theme.textColor};
    border: none;
  }
`
export const SSbx02 = styled(FlexDiv)`
  margin: 0 0 0 auto;
  justify-content: flex-end;
  flex-wrap: nowrap;
  color: #8e9195;
  font-size: 14px;
  width: max-content;
  // max-width: 55%;
  span {
    width: 100%;
    margin: 0 0 6px 0;
    text-align: right;
    min-height: 20px;
  }

  button {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
  }

  button.OrangeBTN {
    background-color: #8ae059;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 6px;

    .fas {
      font-size: 14px;
    }
    &:hover {
      background-color: #70c142;
    }
  }

  .maxTitle {
    background-color: #faecc5;
    margin: 0 0 0 8px;
    font-size: 12px;
    border-radius: 3px;
    padding: 2px 5px;
    color: #000000;
    display: inline-block;
  }
  .imgBox {
    display: inline-block;
    margin: 0 10px;
    padding-top: 6px;
  }
  .fas {
    margin-left: 8px;
  }
`
export const SwapTitle02 = styled(FlexDiv)`
  font: 300 18px/25px 'Press Start 2P', arial;
  color: #565a69;
  padding: 18px 0;
  justify-content: flex-start;

  &.Center {
    justify-content: center;
  }
`
export const SwapTitle03 = styled(FlexDiv)`
  font-size: 14px;
  color: #8e9195;
  font-weight: 600;
  min-height: 42px;
  justify-content: flex-start;
  span {
    margin-left: auto;
  }
`

export const SwapTitle04 = styled(FlexDiv)`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 20px;
  justify-content: flex-start;

  &.smlTitle {
    font: 300 11px/20px 'IBM Plex Mono', arial;
    color: #8e9195;
    margin-top: 20px;
    display: block;
    text-align: center;
    p {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
`

export const SwapBTN01 = styled(AppButton)`
`

export const SwapBTN02 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  border: none;
  background-color: #8ae059;
  width: 152px;
  height: 152px;
  padding: 15px;
  border-radius: 80px;

  .imgBox01 {
    margin-bottom: 12px;
  }

  :hover {
    background-color: #70c142;
  }
`

export const SwapLinkbx = styled(FlexDiv)`
  font-size: 12px;
  color: #ababab;
  font-weight: 400;
  min-height: 46px;
  width: 100%;
  max-width: 430px;

  a {
    color: #ababab;
    margin: 0 6px;
    :hover {
      color: #8ae059;
    }
  }
`

// New export Const for Update Design

export const AlertTitle01 = styled.div`
  font-size: 11px;
  color: #c32b2d;
  margin: 0 6px 15px 6px;
  line-height: 18px;

  & span {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  & a {
    text-decoration: underline;
    color: #c32b2d;
    :hover {
      color: #fff;
    }
  }
`

export const InitialPriceBox = styled(FlexDiv)`
  width: 33.33%;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
  color: #565a69;
  & span {
    margin-bottom: 8px;
  }
`
export const PPClosBTN01 = styled.button`
  display: none;
  width: 20px;
  height: 20px;
  border: 0px;
  outline: none;
  color: #fff;
  background: url(${Xbtn}) left top no-repeat;
  position: absolute;
  right: 0px;
  transition: 0.5s ease all;
  :hover {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`

export const SwapTitle05 = styled(FlexDiv)`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin: 25px 0 15px 0;
  justify-content: flex-start;

  & .helpIco {
    font-size: 14px;
    right: auto;
    top: -7px;
    left: 5px;
  }
`
export const ConnectWallText = styled(FlexDiv)`
  width: 100%;
  max-width: 260px;
  font-size: 14px;
  color: #565a69;
  text-align: center;
  font-weight: 400;
`
export const SSLinkbox01 = styled(FlexDiv)`
  width: 100%;
  font-size: 12px;
  color: #565a69;
  text-align: center;
  font-weight: 400;
  padding: 15px 0;

  & a {
    color: #8ae059;
    margin-left: 4px;
    :hover {
      text-decoration: underline;
    }
  }
`

export const YLMBX = styled(FlexDiv)`
  width: 100%;
  border: 2px solid #545861;
  border-radius: 10px;
  padding: 15px 15px 8px 15px;

  &.v2 {
    border: 0 solid #545861;
    border-radius: 10px;
    padding: 0px 0 8px 0;
  }
`
export const YLTitle01 = styled(FlexDiv)`
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
  span {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
  i {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
  }
`
export const YLTitle02 = styled(FlexDiv)`
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  min-height: 38px;

  &.v2 {
    font-size: 18px;
    font-weight: 700;
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        margin-right: 6px;
      }
    }
  }

  &.v3 {
    align-items: flex-start;
    margin-top: 10px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 700;
    color: #8e9195;
    span {
      text-align: right;
    }
  }
  span {
    display: flex;
    align-items: center;
  }
  i {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    margin-left: 5px;
  }
`

export const YLTitle03 = styled(FlexDiv)`
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  min-height: 38px;
`
export const YLBtnBar = styled(FlexDiv)`
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  .ylBTN01 {
    color: #8ae059;
    margin: 0 auto;
    :hover {
      text-decoration: underline;
    }
  }
  .ylBTN02 {
    background-color: #5d6168;
    text-align: center;
    font-weight: 700;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    padding: 12px 10px;
    width: 48%;
    :hover {
      background-color: #4f545c;
    }
  }
  .ylBTN03 {
    background-color: #8ae059;
    text-align: center;
    font-weight: 700;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    padding: 12px 10px;
    width: 48%;
    :hover {
      background-color: #4f545c;
    }
  }
  .approved {
    background-color: #5d6168 !important;
    text-align: center;
    font-weight: 700;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    padding: 12px 10px;
    width: 48%;
  }
  span {
    margin: 0 auto;
    color: #565a69;
    font-size: 12px;
  }
`
export const TSinputBar = styled(FlexDiv)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
  position: relative;
  &.smbar {
    color: #8e9195;
    input {
      width: 75px;
      margin: 0 8px 0 0;
      color: #fff;
      text-align: center;
    }
  }

  .tsBTN01 {
    width: 75px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 12px 6px;
    background-color: #8ae059;
    border-radius: 10px;
    :hover {
      background-color: #70c142;
    }
  }
  input {
    padding: 10px 20px 10px 10px;
    background-color: transparent;
    border: 2px solid #545861;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    text-align: right;
    width: calc(100% - 85px);
    margin-left: 10px;
  }
  span {
    position: absolute;
    right: 12px;
    color: #8e9195;
  }
`

export const RLTitle01 = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 42px 0 30px 0;

  a {
    color: #8ae059;
    :hover {
      text-decoration: underline;
    }
  }
  &.v2 {
    margin: 0 0 20px 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
  }
`
export const DragorInput = styled.div`
  position: relative;
  // width: 100%;
  height: 55px;
  padding-top: 0;
  padding-bottom: 0;
  // margin: 0 20px;

  .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    background: #8ae059;
    cursor: pointer;
    display: block;
    color: #fff !important;
    font-weight: 700;
    outline: none;
    // position:absolute;
    // top:50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-weight: 400;
    border: 4px solid #fff;
    transform: translate(-50%, calc(-11px + -50%));
  }

  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range__slider-container {
    transition: left 0.3s ease-out;
    z-index: 100;
  }
  .input-range__label {
    color: #aaaaaa;
    font-size: 16px;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }
  .input-range__label--min {
    left: 25px;
    top: 5px;
    z-index: 2;
  }
  .input-range__label--max {
    right: 23px;
    top: 5px;
  }
  .input-range__label--value {
    position: absolute;
    top: -22px;
    z-index: 2;
    color: #fff;
    // left:9px;
    pointer-events: none;
  }
  .input-range__label-container {
    left: -50%;
    position: relative;
    color: #fff;
    font: 500 16px/20px 'IBM Plex Mono', arial;
    pointer-events: none;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }
  .input-range__track {
    background: #393d46;
    cursor: pointer;
    display: block;
    height: 20px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
    border-radius: 15px;
  }
  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: -1px;
    top: 50%;
  }
  .input-range__track--active {
    background: #8ae059;
  }
  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
  .input-range__label--value .input-range__label-container {
    color: #000;
    font-weight: 700;
    font-size: 13px;
  }
`

export const RLBTNBX = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;

  button {
    width: calc(25% - 10px);
    background-color: #2d3037;
    color: #8e9195;
    padding: 4px;
    border-radius: 6px;
    font-size: 11px;
    font-weight: 400;
    :hover,
    &.active {
      background-color: #34383f;
      color: #fff;
    }
  }
`

export const TSinputBar02 = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 12px;

  .apollo-field-switcher label {
    display: block;
    float: left;
    cursor: pointer;
    position: relative;
    height: 42px;
    width: 102px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  .apollo-field-switcher label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  input[type='radio'],
  input[type='checkbox'] {
    border: 1px solid #b4b9be;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    -webkit-appearance: none;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
  }
  .ui-widget input,
  .ui-widget select,
  .ui-widget textarea,
  .ui-widget button {
    font-family: Verdana, Arial, sans-serif;
    font-size: 1em;
  }
  .apollo-field-switcher input[type='radio'],
  .apollo-field-switcher input[type='checkbox'] {
    border: 1px solid #b4b9be;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    -webkit-appearance: none;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
  }
  .apollo-field-switcher label em {
    position: relative;
    display: block;
    height: 42px;
    width: 102px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    font-style: normal;
    color: #8e9195;
    background-color: #16191e;
    border: 2px solid #545861;
    -moz-transition: background 0.15s ease-out;
    -o-transition: background 0.15s ease-out;
    -webkit-transition: background 0.15s ease-out;
    transition: background 0.15s ease-out;
  }
  .apollo-field-switcher label em:before {
    content: attr(data-off);
    right: 14px;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label em:after {
    content: attr(data-on);
    left: 14px;
    opacity: 0;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label span {
    position: absolute;
    top: 4px;
    left: 51px;
    width: 48px;
    height: 35px;
    background-color: #33373e;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    -webkit-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
  }
  .apollo-field-switcher label input:checked ~ em:before {
    opacity: 1;
  }
  .apollo-field-switcher label em:before {
    content: attr(data-off);
    right: 14px;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    z-index: 100;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label input:checked ~ em:after {
    opacity: 1;
    color: #fff;
  }
  .apollo-field-switcher label em:after {
    content: attr(data-on);
    left: 14px;
    opacity: 1;
  }
  .apollo-field-switcher label input:checked ~ em {
    background: #16191e;
  }
  .apollo-field-switcher label input:checked ~ span {
    left: 4px;
    background: #8ae059;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher input[type='checkbox']:checked:before {
    content: '\f147';
    margin: -3px 0 0 -4px;
    color: #1e8cbe;
  }
  .apollo-field-switcher input[type='radio']:checked:before,
  .apollo-field-switcher input[type='checkbox']:checked:before {
    float: left;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    font: 400 21px/1 dashicons;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
export const Arrow = styled(FlexDiv)`
  padding: 20px 0;
  color: #565a69;
  font-size: 16px;
`
export const Errortxt = styled.div`
  font-size: 14px;
  margin: 30px 0 45px;
  line-height: 20px;
  .bold-txt {
    font-weight: 600;
    font-size: 16px;
  }
  .red-color {
    color: #c02a29;
  }
`

export const YellowButton = styled.button`
  text-transform: uppercase;
  background: #ffa;
  color: #16191e !important;
  border-radius: 3px;
  margin-right: 10px;
  padding: 5px 7.5px;
`

export const GreenButton = styled.button`
  background: #8ae059;
  color: #fff !important;
  font-size: 14px !important;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 10px;
  padding: 2px 5px;
  text-transform: uppercase;
`
