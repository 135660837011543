import React from 'react'
import RCSelect from 'react-select'
import styled from 'styled-components'

const RCSelectWrapper = styled.div``

function Select({ ...props }) {
  return (
    <RCSelectWrapper>
      <RCSelect
        styles={{
          control: (styles) => ({
            ...styles,
            border: 0,
            minHeight: 35,
            '&:hover': {
              backgroundColor: '#8ae059',
              color: 'white',
            },
            '&:hover font': {
              color: 'white',
            },
            '&:hover svg': {
              color: 'white',
            },
          }),
          option: (styles) => ({
            ...styles,
            color: 'black',
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            color: 'black',
            '&:hover': {
              color: 'white',
            },
          }),
          singleValue: (styles) => ({
            ...styles,
          }),
        }}
        {...props}
      ></RCSelect>
    </RCSelectWrapper>
  )
}

export default Select
