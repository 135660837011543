import { AppButton } from 'Component/shared/Buttons'
import styled from 'styled-components'
import Media from '../../Theme/media-breackpoint'

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const SwapSSbox01 = styled.div`
  // margin: 10px 10px 0 10px;
  width: 100%;
  max-width: 430px;
  background-color: #16191e;
  border: 2px solid #000;
  min-height: 430px;
  padding: 18px;
  border-radius: 10px;
  &.active {
    border-color: #8be15a;
    -webkit-box-shadow: 0px 1px 25px 3px rgb(139 224 90 / 40%);
    box-shadow: 0px 1px 25px 3px rgb(139 224 90 / 40%);
    margin-bottom: 0;
  }
  ${Media.md} {
    max-width: 100%;
  }
`

export const SwapTitle01 = styled(FlexDiv)`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 24px;
  position: relative;

  img {
    margin: 0 16px 0 0;
  }
  .helpIco {
    position: absolute;
    right: 0px;
  }
`

export const SwapInputMbx = styled(FlexDiv)`
  border: 2px solid #545861;
  border-radius: 10px;
  min-height: 90px;
  padding: 12px 14px;
  .swap-title{
    width: 100%;
    margin: 0 0 6px 0;
    font-weight: 700;
    color: #8e9195;
  }
`

export const SwapBTN01 = styled(AppButton)`
`

export const SwapTitle03 = styled(FlexDiv)`
  font-size: 14px;
  color: #8e9195;
  font-weight: 600;
  min-height: 42px;
  justify-content: flex-start;
  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
    margin: 0;
  }
`

export const SSbx01 = styled(FlexDiv)`
  margin: 0 auto 0 0;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: #8e9195;
  font-size: 14px;
  width: auto;
  // max-width: 40%;
  input {
    opacity: 0.8;
    background-color: transparent;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    height: 25px;
    color: ${(props) => props.theme.textColor};
    border: none;
  }
`

export const SSbx02 = styled(FlexDiv)`
  margin: 0 0 0 auto;
  justify-content: flex-end;
  color: #8e9195;
  font-size: 14px;
  width: auto;
  // max-width: 55%;
  span {
    width: 100%;
    margin: 0 0 6px 0;
    text-align: right;
    min-height: 20px;
  }

  button {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
  }

  button.OrangeBTN {
    background-color: #8ae059;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 6px;

    .fas {
      font-size: 14px;
    }
    &:hover {
      background-color: #70c142;
    }
  }

  .maxTitle {
    background-color: #faecc5;
    margin: 0 0 0 8px;
    font-size: 12px;
    border-radius: 3px;
    padding: 2px 5px;
    color: #000000;
    display: inline-block;
  }
  .imgBox {
    display: inline-block;
    margin: 0 10px;
    padding-top: 6px;
  }
  .fas {
    margin-left: 8px;
  }
`
export const YellowButton = styled.button`
  text-transform: uppercase;
  background: #ffa;
  color: #16191e !important;
  border-radius: 3px;
  margin-right: 10px;
  padding: 5px 7.5px;
`

export const SwapTitle02 = styled(FlexDiv)`
  font: 300 18px/25px 'Press Start 2P', arial;
  color: #565a69;
  padding: 18px 0;
  justify-content: flex-start;

  &.Center {
    justify-content: center;
  }
`
