import { ACTION_TYPES } from './cutomTokenSlice'

export function setCustomTokens(tokens) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.set,
      payload: tokens,
    })
  }
}

export function removeCustomTokens(address) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.remove,
      payload: address,
    })
  }
}

export function addCutomToken(token) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.add,
      payload: token,
    })
  }
}
