import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import Modal from '../modal'
import { useHistory } from 'react-router-dom'
import AddYrDex from './addYourDex'

import Select from '../shared/select'
import Dexes from '../../../public/json/dexes.json'
import { PulseAnimation } from '../shared/common'
import { CHAINID_BY_NAME, CHAIN_NAME_BY_ID } from '../../constants'
import { useAppSnackbar, useWallet } from '../../hooks'

const DexesData = Dexes.dexes

const defaultNetworkOption = {
  value: '',
  label: 'All',
}

export default function DexesPopup({ isOpen, dismiss }) {
  const { push } = useHistory()
  const [tokenBox, setTokenBox] = useState(DexesData)
  const [search, setSearch] = useState('')
  const [selectedNetwork, setSelectedNetwork] = useState(defaultNetworkOption)
  const [showAdd, setShowAdd] = useState(false)
  const [grid, setShow] = React.useState()
  const [openSnackbar] = useAppSnackbar()
  const { active, chainId, switchNetwork } = useWallet()

  const handleNetworkSelect = useCallback(({ value, label }) => {
    setSelectedNetwork({ value, label })
  }, [])

  const handleSelectDex = useCallback(
    async ({ disabled, id, type }) => {
      // type is network name
      if (disabled) return
      const targetChain = CHAINID_BY_NAME[type]
      if (active && chainId !== parseInt(targetChain, 10)) {
        // switch network
        // if success, go to page or stay on current page.
        try {
          await switchNetwork(targetChain)
          push(`/${id}?chain=${type || 'eth'}`)
          dismiss()
        } catch (error) {
          openSnackbar('Please Switch Network')
          console.error(error)
        }
      } else {
        push(`/${id}?chain=${type || 'eth'}`)
        dismiss()
      }
    },
    // disabled for openSnackbar
    // eslint-disable-next-line
    [push, switchNetwork, chainId, active, dismiss]
  )

  const networkOptions = useMemo(() => {
    let networks = DexesData.map((dex) => ({ value: dex.type, label: dex.type })).filter((option, i, self) => self.findIndex((opt) => opt.value === option.value) === i)
    networks = [defaultNetworkOption, ...networks]
    return networks
  }, [])

  useEffect(() => {
    setTokenBox(DexesData.filter((e) => e.title.toLowerCase().startsWith(search.toLowerCase()) && e.type.startsWith(selectedNetwork.value)))
  }, [search, selectedNetwork])

  useEffect(() => {
    if (chainId) {
      const chainName = CHAIN_NAME_BY_ID[chainId]
      setSelectedNetwork({
        value: chainName,
        label: chainName,
      })
    }
  }, [chainId])

  // filter search box uniswap
  useEffect(() => {
    setSearch('')
    // setTokenBox(DexesData);
  }, [isOpen])

  return isOpen ? (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <AddYrDex isOpen={showAdd} dismiss={() => setShowAdd(false)} />
      <PopupSearchbar>
        <SearchBox01>
          <PopSearchIcon>
            <i className='fas fa-search'></i>
          </PopSearchIcon>
          <PopInput value={search} placeholder='Search by dex name' onInput={(e) => setSearch(e.target.value)} />
        </SearchBox01>
        <SearchBox02>
          <FilterBTN className={`${grid ? '' : 'active'}`} onClick={() => setShow(false)}>
            <i className='fas fa-square'></i>
          </FilterBTN>
          <FilterBTN className={`${grid ? 'active' : ''}`} onClick={() => setShow(true)}>
            <i className='fas fa-th-list'></i>
          </FilterBTN>
          {/* <PulseAnimation rgbColor={[139, 224, 90]} className='ml-10'>
            <Select className='text-uppercase min-w-150 ' value={selectedNetwork} options={networkOptions} placeholder='All' onChange={handleNetworkSelect} />
          </PulseAnimation> */}
        </SearchBox02>
      </PopupSearchbar>

      <PopupCenterBox className={`${grid ? 'listView' : ''}`}>
        {tokenBox
          ? tokenBox.map((data) => {
              return (
                <TokenMbox 
                  style={
                    data.disabled 
                      ? { opacity: '.15', cursor: 'not-allowed' } 
                      : {}
                  }
                  key={data.title}
                >
                  <TokenInnerBox onClick={() => handleSelectDex(data)}>
                    {data.img ? (
                      <DexCardBox>
                        <img src={data.img} alt='' />
                      </DexCardBox>
                    ) : null}
                    {data.title}
                  </TokenInnerBox>
                </TokenMbox>
              )
            })
          : null}
        {/* !! dont remove it. we will use in future */}
        <TokenMbox>
          <TokenInnerBox
            onClick={() => setShowAdd(true)}
          >
            Add your DEX
          </TokenInnerBox>
        </TokenMbox>
      </PopupCenterBox>
    </Modal>
  ) : (
    <></>
  )
}

const PopupSearchbar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 50px 15px 50px 15px;
`

const SearchBox01 = styled.div`
  position: relative;
  width: 100%;
`

const SearchBox02 = styled.div`
  display: flex;
`
const PopSearchIcon = styled.span`
  position: absolute;
  top: 5px;
  left: 0px;
  color: #fff;
  font-size: 24px;
`

const PopInput = styled.input`
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  line-height: normal;
  background-color: transparent;
  border: none;
  width: 100%;
  padding-left: 60px;
`

const FilterBTN = styled.button`
  color: #fff;
  font-size: 14px;
  margin: 0px 8px;
  padding: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  & :hover,
  &.active {
    color: #8be05a;
  }
`

const PopupCenterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TokenMbox = styled.div`
  width: 25%;
  text-align: center;
  display: flex;

  ${PopupCenterBox}.listView & {
    width: 100%;
    text-align: left;
  }
`
const TokenInnerBox = styled.a`
  background-color: #2c2f36;
  border-radius: 10px;
  transition: 0.5s ease all;
  box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 5%);
  margin: 0px 18px 30px 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 35px;
  color: #fff;
  font-size: 18px;
  flex-direction: column;
  cursor: pointer;

  ${PopupCenterBox}.listView & {
    padding: 20px 25px;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    border-bottom: 1px solid #393d46;
    :hover {
      background-color: #21242b;
      color: #8b8e93;
      img {
        opacity: 0.5;
      }
    }
  }

  :hover {
    box-shadow: 0px 0px 15px 5px rgb(139 224 90 / 40%);
    color: #fff;
  }
`

const DexCardBox = styled.div`
  max-width: 100px;
  max-height: 100px;
  text-align: center;
  margin-bottom: 20px;
  ${PopupCenterBox}.listView & {
    max-height: none;
    margin-bottom: 0;
    margin-right: 20px;
  }
  img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
  }
`
