export const isValidNumber = (num) => {
  var regx = new RegExp('[+-]?([0-9]*[.])?[0-9]+')
  if (isNaN(num)) {
    return false
  }
  if (num === '' || num === '.' || regx.test(num)) {
    return true
  } else {
    return false
  }
}
