import React from 'react'
import styled from 'styled-components'
import Modal from '../modal'
import ReactTooltip from 'react-tooltip'

export default function AddYrDex(props) {
  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss}>
      <AYDTitle01>Add Your DEX</AYDTitle01>
      <AYDinputBX>
        Your URL DEX
        <input type='text' />
      </AYDinputBX>
      <AYDBTNBX>
        <button>ADD YOUR DEX</button>
      </AYDBTNBX>
      <ReactTooltip effect='solid' type='light' className='myTip' />
    </Modal>
  )
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const AYDTitle01 = styled(FlexDiv)`
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
  margin: 160px 0 30px 0;
`
const AYDinputBX = styled(FlexDiv)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 700;
  input {
    width: 100%;
    margin: 22px 0 40px 0;
    height: 58px;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: transparent;
    color: #fff;
    padding: 8px 15px;
  }
`
const AYDBTNBX = styled(FlexDiv)`
  width: 100%;
  margin: 40px 0 20px 0;

  button {
    width: 100%;
    max-width: 665px;
    background-color: #8be05a;
    border-radius: 10px;
    border: none;
    padding: 22px 12px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    :hover {
      background-color: #fea100;
    }
  }
`

// const PPMainBx = styled.div`
//  width:100%; max-width: 431px; margin:0 auto; background-color:#2c2f36; border:2px solid #000; display:block; border-radius: 10px;
// `;

//   const PPClosBTN01 = styled.button`
//  width:20px; height:20px; border:0px; outline:none;  color:#fff; background:url(${Xbtn}) left top no-repeat; position:absolute; right:0px;
//  transition: 0.5s ease all;
//  :hover{ opacity:0.7; transform: rotate(180deg );}
// `;

//   const PPsBx01 = styled(FlexDiv)`
//     width:100%; padding:30px 30px 10px 30px; border-bottom:#3c3f46 1px solid;
//     &.v2{  border-bottom:0px;  }
// `;

//   const PPsBx02 = styled(FlexDiv)`
//     width:100%; padding:20px 30px 20px 30px; border-top:#3c3f46 1px solid;

//     &.v2{ font-size:12px; border-top:0px; button{ color:#8be05a;} }
//      & button { border:0px; outline:none; background-color:transparent; color:#fff;
//     :hover{ opacity:0.7;}
//     }
// `;

//   const PPselectTokenBTN = styled(FlexDiv)`
//   justify-content: flex-start; width:100%; padding:8px 0;

//   button{  display:inline-flex; color:#fff; border-radius:10px; border:1px solid #545861; align-items:center; justify-content:flex-start; padding:4px 10px 4px 6px; margin:0 8px 8px 0;

//   :hover{ background-color:#545861;}
//   &.active { background-color:#1f2127; border-color:#1f2127;}
//   }

// `;

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

//   const PPstitle02 = styled(PPstitle01)`
//      align-items: flex-start; justify-content:space-between; padding:10px 0; width:100%; color:#fff; font-size:12px; font-weight:400;

//     & button { border:0px; outline:none; background-color:transparent; color:#fff;

//     :hover{ opacity:0.7;} }

// `;

//   const PopInput01 = styled.input`
//     font-weight:400;
//     font-size:14px;
//     color: #ffffff;
//     line-height: normal;
//     background-color: transparent;
//     border:2px solid #545861;
//     width: 100%; padding:11px 8px; border-radius:10px; margin:10px 0;
// `;

// const CWBTNBX = styled(FlexDiv)`

//     width: 100%; padding:10px 30px 0px 30px;

//     button{ width:100%; height:62px; border:1px solid #545861; border-radius:10px;  display:flex; align-items:center; justify-content:flex-start; font-size:14px; padding:10px 14px; color: #ffffff; position:relative; margin:0 0 15px 0;

//     i{ width:32px; height:32px; position:absolute; right:11px; top:13px; display:flex; align-items:center; justify-content:center;  }

//     :hover{ background-color:#32353b; }

//     }

// `
// const PPListtoken = styled.button`
//      width:100%; display:flex; align-items: center; justify-content:flex-start; font-size: 16px; color: #fff; padding: 15px 30px;

//         & i{margin-left:auto;}

//      :hover{background-color:#24272C;}
// `;

//   const ImgBx = styled(FlexDiv)`
//   width:23px; height:23px; text-align: center; margin-right:12px;    border-radius:15px; overflow:hidden;

//   img { max-width:100%; height:auto;}

// `;
