import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Modal from '../modal'
import ReactTooltip from 'react-tooltip'
import { Connect_TYPE } from '../../Connectors'
import { useWallet } from '../../hooks'
import { withSnackbar } from 'react-simple-snackbar'

import CWIco01 from '../../Assets/images/cwICON-01.png'
import CWIco02 from '../../Assets/images/cwICON-02.png'
import CWIco03 from '../../Assets/images/cwICON-03.png'
import CWIco04 from '../../Assets/images/cwICON-04.png'

import Xbtn from '../../Assets/images/closeBTN.png'

function ConnectWallet({ isOpen, dismiss }) {
  const { active, connectWallet } = useWallet()

  useEffect(() => {
    if (active) {
      dismiss()
    }
  }, [active, dismiss])

  return (
    <Modal isOpen={isOpen} dismiss={dismiss} disableCloseBtn={true}>
      <PPMainBx>
        <PPsBx01 className='v2'>
          <PPstitle01>
            Connect wallet
            <PPClosBTN01 onClick={dismiss} />
          </PPstitle01>
        </PPsBx01>

        <CWBTNBX>
          <button onClick={() => connectWallet(Connect_TYPE.BINANCE_CHAIN)}>
            Install Binance Chain Wallet{' '}
            <i>
              <img src={CWIco01} alt='' />{' '}
            </i>
          </button>

          <button onClick={() => connectWallet(Connect_TYPE.METAMASK)}>
            Install MetaMask{' '}
            <i>
              <img src={CWIco02} alt='' />{' '}
            </i>
          </button>
          <button onClick={() => connectWallet(Connect_TYPE.TRUST_WALLET)}>
            Download TrustWallet App{' '}
            <i>
              <img src={CWIco03} alt='' />{' '}
            </i>
          </button>
          <button onClick={() => connectWallet(Connect_TYPE.MATH_WALLET)}>
            Get MathWallet App{' '}
            <i>
              <img src={CWIco04} alt='' />{' '}
            </i>
          </button>
        </CWBTNBX>

        <PPsBx02 className='v2'>
          New to Binance Smart Chain? <br />
          <button>{/*Having trouble finding a token?*/} Learn more about wallets</button>
        </PPsBx02>
      </PPMainBx>

      <ReactTooltip effect='solid' type='light' className='myTip' />
    </Modal>
  )
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const PPMainBx = styled.div`
  width: 100%;
  max-width: 431px;
  margin: 0 auto;
  background-color: #16191e;
  border: 2px solid #000;
  display: block;
  border-radius: 10px;
`

const PPClosBTN01 = styled.button`
  width: 20px;
  height: 20px;
  border: 0px;
  outline: none;
  color: #fff;
  background: url(${Xbtn}) left top no-repeat;
  position: absolute;
  right: 0px;
  transition: 0.5s ease all;
  :hover {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;

  &.v2 {
    border-bottom: 0px;
  }
`

const PPsBx02 = styled.div`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;
  text-align: center;

  &.v2 {
    font-size: 12px;
    border-top: 0px;
    button {
      color: #8be05a;
    }
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;
    :hover {
      opacity: 0.7;
    }
  }
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

const CWBTNBX = styled(FlexDiv)`
  width: 100%;
  padding: 10px 30px 0px 30px;

  button {
    width: 100%;
    height: 62px;
    border: 1px solid #545861;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    padding: 10px 14px;
    color: #ffffff;
    position: relative;
    margin: 0 0 15px 0;

    i {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 11px;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover {
      background-color: #32353b;
    }
  }
`

export default withSnackbar(ConnectWallet)
