import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useWeb3 } from 'hooks'
import { smartPath } from '../utils/new'
import factoryAbi from '../abi/factory.json'
import ERC20Abi from '../abi/ERC20.json'
import uniswapAbi from '../abi/uniswap.json'
import pairAbi from '../abi/pair.json'
import { MAIN_ZERO_ADDRESS, ZERO_ADDRESS } from '../constants/addresses'
import { _toFixed, toBig } from '../services/data_service'
import { getDecimal } from '../utils/new'
import fromExponential from 'from-exponential'
import Web3Helpers from '../utils/web3Helpers'
import { DEFAULT_TIMEOUT } from '../constants'
import { useAppSnackbar } from './useAppSnackbar'
import { defaultToken } from '../constants/index'

const typeOptions = {
  defaultTokenA: defaultToken,
  defaultTokenB: defaultToken,
}

/**
 * @todo need to make some change for multihops
 * @returns
 */
export function useLiquidity({ W_TOKEN_ADDRESS, account, factoryAddress, routerAddress, options = typeOptions }) {
  const [openSnackbar] = useAppSnackbar()
  const [tokenA, _setTokenA] = useState(options?.defaultTokenA ?? defaultToken) // from token
  const [tokenB, _setTokenB] = useState(options?.defaultTokenB ?? defaultToken) //  to token
  const [pairAddress, setPairAddress] = useState(ZERO_ADDRESS)
  const [amountA, _setAmountA] = useState(0)
  const [amountB, _setAmountB] = useState(0)

  const [balanceA, _setBalanceA] = useState(0)
  const [balanceB, _setBalanceB] = useState(0)

  const [removeLiquidityPriceA, _setRemoveLiquidityPriceA] = useState(0)
  const [removeLiquidityPriceB, _setRemoveLiquidityPriceB] = useState(0)

  const [lpTokenValue, _setLPTokenValue] = useState(0)
  const [totalLiqValues, _setTotalLiqValues] = useState({ from: 0, to: 0 })

  const [tokenRatio, _setTokenRatio] = useState(0)
  const [impact, _setImpact] = useState(0)

  const [removeLiqValue, setRemoveLiqValue] = useState(0)
  const [allowedToRemove, setAllowedToRemove] = useState(0)
  const [removeAllowance, setRemoveAllowance] = useState(0)

  const [fromAmount, setFromAmount] = useState(0)
  const [toAmount, setToAmount] = useState(0)

  const [approved, setApproved] = useState(false)
  const web3 = useWeb3()

  const factoryContract = useMemo(() => {
    return new web3.eth.Contract(factoryAbi, factoryAddress)
    // disabled for web3
    // eslint-disable-next-line
  }, [factoryAddress])

  /**
   *
   * @param {'tokenA' | 'tokenB'} type
   */
  const quote = useCallback(
    async (_amount, type = 'tokenA') => {
      let comapreAddress = ZERO_ADDRESS,
        liquidityAmount = 0,
        setValueRef = () => {}
      if (type === 'tokenA') {
        setValueRef = _setAmountB
      } else {
        setValueRef = _setAmountA
      }
      if (tokenA.address && tokenB.address && pairAddress !== ZERO_ADDRESS) {
        var [tokenAAddress, tokenBAddress] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])
        let balance = new web3.eth.Contract(ERC20Abi, tokenAAddress)
        let valueA = await balance.methods.decimals().call({ from: account })
        let bal = new web3.eth.Contract(ERC20Abi, tokenBAddress)
        let valueB = await bal.methods.decimals().call({ from: account })
        let tokenpair = new web3.eth.Contract(pairAbi, pairAddress)
        let amount = await tokenpair.methods.getReserves().call({ from: account })
        let addre = await tokenpair.methods.token0().call({ from: account })
        let inputAmount = _amount

        if (type === 'tokenA') {
          comapreAddress = tokenAAddress
        } else {
          comapreAddress = tokenBAddress
        }
        if (comapreAddress.toLowerCase() === addre.toLowerCase()) {
          inputAmount = inputAmount * 10 ** valueA
          let reserveA = amount[0]
          let reserveB = amount[1]
          let amountB = (inputAmount * reserveB) / reserveA
          liquidityAmount = amountB / 10 ** valueB
        } else {
          inputAmount = inputAmount * 10 ** valueB
          let reserveA = amount[1]
          let reserveB = amount[0]
          let amountB = (inputAmount * reserveB) / reserveA
          liquidityAmount = amountB / 10 ** valueA
        }

        setValueRef(liquidityAmount)
      } else {
        setValueRef(0)
      }
    },
    [W_TOKEN_ADDRESS, tokenA.address, tokenB.address, web3, pairAddress, account]
  )

  const setTokenA = useCallback((value) => {
    _setTokenA(value)
  }, [])
  const setTokenB = useCallback((value) => {
    _setTokenB(value)
  }, [])
  const setAmountA = useCallback(
    (value) => {
      _setAmountA(value)
      quote(value, 'tokenA')
    },
    [quote]
  )
  const setAmountB = useCallback(
    (value) => {
      _setAmountB(value)
      quote(value, 'tokenB')
    },
    [quote]
  )

  /**
   *
   * @param {Number} _amount
   * @param {'removeLiquidityPriceA' | 'removeLiquidityPriceB'} type
   */
  const getPriceValue = useCallback(
    async (_amount, type) => {
      var [tokenAAddress, tokenBAddress] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])
      let setValueRef = () => {}
      let tokenToCompare = ZERO_ADDRESS
      if (type === 'removeLiquidityPriceA') {
        tokenToCompare = tokenBAddress
        setValueRef = _setRemoveLiquidityPriceA
      } else {
        tokenToCompare = tokenAAddress
        setValueRef = _setRemoveLiquidityPriceB
      }
      if (tokenAAddress && tokenBAddress && pairAddress && pairAddress !== ZERO_ADDRESS) {
        let balance = new web3.eth.Contract(ERC20Abi, tokenAAddress)
        var valueA = await balance.methods.decimals().call({ from: account })

        let bal = new web3.eth.Contract(ERC20Abi, tokenBAddress)
        let valueB = await bal.methods.decimals().call({ from: account })

        let tokenpair = new web3.eth.Contract(pairAbi, pairAddress)
        let amount = await tokenpair.methods.getReserves().call({ from: account })
        let addre = await tokenpair.methods.token0().call({ from: account })

        if (tokenToCompare.toLowerCase() === addre.toLowerCase()) {
          _amount = _amount * 10 ** valueA
          let reserveA = amount[0]
          let reserveB = amount[1]
          let amountB = (_amount * reserveB) / reserveA
          amountB = _toFixed(amountB / 10 ** valueB, 3)
          setValueRef(amountB)
        } else {
          _amount = _amount * 10 ** valueB
          let reserveB = amount[0]
          let reserveA = amount[1]
          let amountB = (_amount * reserveB) / reserveA || 0
          amountB = _toFixed(amountB / 10 ** valueA, 3)
          setValueRef(amountB)
        }
      } else {
        setValueRef(0)
      }
    },
    /* eslint-disable-next-line */ // disabled for web3
    [tokenA, tokenB, pairAddress, W_TOKEN_ADDRESS, account]
  )

  const removeLiquidity = useCallback(async () => {
    let routerContract = new web3.eth.Contract(uniswapAbi, routerAddress)
    let [tokenAAddress, tokenBAddress] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])

    let tokenpair = await new web3.eth.Contract(pairAbi, pairAddress)

    let result = await tokenpair.methods.balanceOf(account).call({ from: account })

    let allowanceLiq = +removeLiqValue === 100 ? result : fromExponential((result * removeLiqValue) / 100)
    let liquidity = +removeLiqValue === 100 ? result : toBig(parseInt(fromExponential((result * removeLiqValue) / 100)))
    let liquidityETH = +removeLiqValue === 100 ? result : toBig(parseInt((result * removeLiqValue) / 100))

    //   let ERC20 = new web3.eth.Contract(ERC20

    const token0address = await tokenpair.methods.token0().call({ from: account })
    let balance = new web3.eth.Contract(ERC20Abi, token0address)
    let balance0 = balance.methods.balanceOf(pairAddress).call({ from: account })
    let token1address = await tokenpair.methods.token1().call({
      from: account,
    })
    let token1Contract = new web3.eth.Contract(ERC20Abi, token1address)
    let balance1 = await token1Contract.methods.balanceOf(pairAddress).call({
      from: account,
    })
    let totalsupply = await tokenpair.methods.totalSupply().call({
      from: account,
    })
    let amountAmin = 0
    let amountBmin = 0
    let amount0 = (liquidity * balance0) / totalsupply
    let amount1 = (liquidity * balance1) / totalsupply
    const now = new window.Date().getTime()
    if (tokenAAddress === W_TOKEN_ADDRESS) {
      await routerContract.methods
        .removeLiquidityETH(tokenBAddress, liquidityETH, amountAmin, amountBmin, account, now + 1000 * 10000) // TODO need to apply time out from setting
        .send({
          from: account,
          value: token0address === W_TOKEN_ADDRESS ? amount0 : amount1,
        })
      setRemoveLiqValue(0)
      setFromAmount(0)
      setToAmount(0)
    } else {
      if (Number(allowedToRemove) > Number(allowanceLiq)) {
        await routerContract.methods.removeLiquidity(tokenAAddress, tokenBAddress, liquidity, amountAmin, amountBmin, account, now + 10000000).send({
          from: account,
        })
        setRemoveLiqValue(0)
        setFromAmount(0)
        setToAmount(0)
      } else {
        openSnackbar('Please approve the amount to remove')
      }
    }
  }, [removeLiqValue, routerAddress, allowedToRemove, W_TOKEN_ADDRESS, account, pairAddress, tokenA.address, tokenB.address, web3.eth.Contract, openSnackbar])

  const approveLiquidity = useCallback(async () => {
    const tokenAAddress = tokenA.address
    const tokenBAddress = tokenB.address
    const web3Helpers = new Web3Helpers(web3)
    const amountToApproveA = await web3Helpers.compareBalance(tokenAAddress, amountA, account)
    const amountToApproveB = await web3Helpers.compareBalance(tokenBAddress, amountB, account)
    const amount = [amountToApproveA, amountToApproveB]
    if (tokenAAddress === ZERO_ADDRESS || tokenBAddress === ZERO_ADDRESS) {
      var tokenapprove
      var amountapprove
      if (tokenAAddress === ZERO_ADDRESS) {
        tokenapprove = tokenBAddress
        amountapprove = amount[1]
      } else {
        tokenapprove = tokenAAddress
        amountapprove = amount[0]
      }
      let ercContract = await new web3.eth.Contract(ERC20Abi, tokenapprove)
      let allowance = await ercContract.methods.allowance(account, routerAddress).call({ from: account })
      if (allowance >= parseFloat(amountapprove)) {
        setApproved(true)
        openSnackbar('Token already approved, click add liquidity to continue')
      } else {
        ercContract.methods
          .approve(routerAddress, amountapprove)
          .send({ from: account })
          .then((res) => {
            setApproved(true)
            openSnackbar('Transaction Approved')
          })
          .catch((err) => {
            console.error(err)
            openSnackbar('Transaction Approval failed')
          })
      }
    } else {
      let ercContract = await new web3.eth.Contract(ERC20Abi, tokenAAddress)
      let allowance = await ercContract.methods.allowance(account, routerAddress).call({ from: account })
      if (allowance >= parseFloat(amount[0])) {
        let ercCont = new web3.eth.Contract(ERC20Abi, tokenAAddress)
        ercCont.methods
          .allowance(account, routerAddress)
          .call({ from: account })
          .then((allowance1) => {
            if (allowance1 >= parseFloat(amount[1])) {
              openSnackbar('Token already approved, click add liquidity to continue')
              setApproved(true)
            } else {
              let ercCont = new web3.eth.Contract(ERC20Abi, tokenAAddress)
              ercCont.methods
                .approve(routerAddress, amount[1])
                .send({ from: account })
                .then((result) => {
                  openSnackbar('Token approved successfully')
                  setApproved(true)
                })
                .catch((err) => {
                  console.error(err)
                  openSnackbar(err.code === 4001 ? 'Token approval rejected' : 'Token approval failed')
                })
            }
          })
          .catch((err) => {
            openSnackbar(err.code === 4001 ? 'Token approval rejected' : 'Token approval failed')
          })
      } else {
        const amountACall = ercContract.methods.approve(routerAddress, amount[0]).send({ from: account })
        let ercCont = new web3.eth.Contract(ERC20Abi, tokenAAddress)
        const amountBCall = ercCont.methods.approve(routerAddress, amount[1]).send({ from: account })
        Promise.all([amountACall, amountBCall])
          .then((res1, res2) => {
            setApproved(true)
          })
          .catch((err) => {
            console.error(err)
            openSnackbar(err.code === 4001 ? 'Token approval rejected' : 'Token approval failed')
          })
      }
    }
    // disabled for openSnackbar and web3
    // eslint-disable-next-line
  }, [account, amountA, amountB, routerAddress, tokenA.address, tokenB.address])

  const addLiquidity = useCallback(async () => {
    if (!tokenA.address || !tokenB.address) return openSnackbar('Please choose both tokens')
    if (!amountA || !amountB) return openSnackbar('Please choose a valid amount')
    let tokenAAddress = tokenA.address
    let tokenBAddress = tokenB.address
    const web3Helpers = new Web3Helpers(web3)

    const allowanceA = await web3Helpers.findAllowedAmount(tokenAAddress, amountA, account, routerAddress)
    const allowanceB = await web3Helpers.findAllowedAmount(tokenBAddress, amountB, account, routerAddress)

    const fromTokenDecimals = await getDecimal(web3.eth, tokenAAddress)
    const toTokenDecimals = await getDecimal(web3.eth, tokenBAddress)
    //.........................APRROVE MAX AMOUNT OF WALLLET.................................
    let liquidity = new web3.eth.Contract(uniswapAbi, routerAddress)
    let amountADesired = toBig(fromExponential(parseInt(parseFloat(amountA) * Math.pow(10, fromTokenDecimals))))
    let amountBDesired = toBig(fromExponential(parseInt(parseFloat(amountB) * Math.pow(10, toTokenDecimals))))
    let amountAMin = 1
    let amountBMin = 1
    const timeOut = new Date().getTime() + DEFAULT_TIMEOUT
    if (!allowanceA && !allowanceB) {
      if (tokenAAddress === ZERO_ADDRESS || tokenBAddress === ZERO_ADDRESS) {
        var tokenaddress
        var amountTokenDesired
        var amountethDesired
        if (tokenAAddress === ZERO_ADDRESS) {
          tokenaddress = tokenBAddress
          if (amountADesired > 10 ** fromTokenDecimals) {
            amountethDesired = amountADesired
            amountTokenDesired = amountBDesired
          } else {
            amountethDesired = amountADesired
            amountTokenDesired = amountBDesired
          }
        } else {
          tokenaddress = tokenAAddress
          if (amountBDesired > 10 ** toTokenDecimals) {
            amountethDesired = amountBDesired
            amountTokenDesired = amountADesired
          } else {
            amountethDesired = amountBDesired
            amountTokenDesired = amountADesired
          }
        }
        liquidity.methods
          .addLiquidityETH(tokenaddress, amountTokenDesired, amountAMin, amountBMin, account, timeOut)
          .send({
            from: account,
            value: amountethDesired,
          })
          .then((result) => {
            openSnackbar('Transaction Succeed!')
            let transactionHash = result.transactionHash
            localStorage.setItem('transactionHash', transactionHash)
            _setTokenA(defaultToken)
            _setTokenB(defaultToken)
            setAmountA(0)
            setAmountB(0)
            _setBalanceA(0)
            _setBalanceB(0)
          })
          .catch((err) => {
            if (err.code !== -32602) {
              openSnackbar(err.code === 4001 ? 'Transaction Rejected' : 'Transaction failed')
            } else {
              openSnackbar('Gas fees is too low.')
            }
          })
      } else {
        liquidity.methods
          .addLiquidity(tokenAAddress, tokenBAddress, amountADesired, amountBDesired, amountAMin, amountBMin, account, timeOut)
          .send({ from: account })
          .then((result) => {
            openSnackbar('Transaction Succeed!')
            let transactionHash = result.transactionHash
            localStorage.setItem('transactionHash', transactionHash)
            // this.setState({ transaction: true });
            _setTokenA(defaultToken)
            _setTokenB(defaultToken)
            setAmountA(0)
            setAmountB(0)
            _setBalanceA(0)
            _setBalanceB(0)
          })
          .catch((err) => {
            if (err.code !== -32602) {
              openSnackbar(err.code === 4001 ? 'Transaction Rejected' : 'Transaction failed')
            } else {
              openSnackbar('Gas fees is too low.')
            }
          })
      }
    } else {
      approveLiquidity()
    }
    // disabled for openSnackbar & web3
    // eslint-disable-next-line
  }, [account, amountA, amountB, routerAddress, setAmountA, setAmountB, tokenA.address, tokenB.address, approveLiquidity])

  const useMaxAmount = useCallback(async () => {
    const web3Helpers = new Web3Helpers(web3)
    let gasPrice = await web3.eth.getGasPrice()
    var block = await web3.eth.getBlock('latest')
    let gas = block.gasLimit
    const product = +gasPrice * (+gas + 200000)
    let decimal = await web3Helpers.getDecimal(tokenA.address)
    const calculated = +balanceA - product / Math.pow(10, decimal)
    if (calculated <= 0) {
      openSnackbar('Token balance is smaller than estimated gas fees.')
    } else {
      setAmountA(+balanceA && calculated > 0 ? String(calculated / Math.pow()) : '')
    }
    // disabled for web3 and openSnackbar
    // eslint-disable-next-line
  }, [balanceA, setAmountA, tokenA.address])

  const getRemoveLiquidityValues = useCallback(async () => {
    if (tokenA.address && tokenB.address && pairAddress && account) {
      let liquidity
      let [tokenAAddress, tokenBAddress] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])
      const tokenADecimals = await getDecimal(web3.eth, tokenAAddress)
      const tokenBDecimals = await getDecimal(web3.eth, tokenBAddress)
      let tokenpair = new web3.eth.Contract(pairAbi, pairAddress)
      let result = await tokenpair.methods.balanceOf(account).call({ from: account })
      //   console.log(result)
      if (+result === 0) {
        return openSnackbar('You do not have enough liquidity!')
      }

      liquidity = fromExponential((result * removeLiqValue) / 100)

      const token0address = await tokenpair.methods.token0().call({ from: account })
      let balance = new web3.eth.Contract(ERC20Abi, token0address)
      const balance0 = await balance.methods.balanceOf(pairAddress).call({ from: account })
      const token1address = await tokenpair.methods.token1().call({
        from: account,
      })
      let balance1Contract = new web3.eth.Contract(ERC20Abi, token1address)
      const balance1 = await balance1Contract.methods.balanceOf(pairAddress).call({
        from: account,
      })
      const totalsupply = await tokenpair.methods.totalSupply().call({
        from: account,
      })
      let amount0 = ((liquidity * balance0) / totalsupply / 10 ** tokenADecimals).toFixed(4)
      let amount1 = ((liquidity * balance1) / totalsupply / 10 ** tokenBDecimals).toFixed(4)

      let amt0 = ((allowedToRemove * balance0) / totalsupply / 10 ** tokenADecimals).toFixed(4)
      let amt1 = ((allowedToRemove * balance1) / totalsupply / 10 ** tokenBDecimals).toFixed(4)

      if (String(token0address).toLowerCase() === String(tokenAAddress).toLowerCase()) {
        _setTotalLiqValues({
          from: amt0,
          to: amt1,
        })
        setFromAmount(amount0)
        setToAmount(amount1)
      } else {
        _setTotalLiqValues({
          from: amt1,
          to: amt0,
        })
        setFromAmount(amount1)
        setToAmount(amount0)
      }
    } else {
      _setTotalLiqValues({
        from: 0,
        to: 0,
      })
      setFromAmount(0)
      setToAmount(0)
    }
    // disabled for web3 & openSnackbar
    // eslint-disable-next-line
  }, [W_TOKEN_ADDRESS, pairAddress, allowedToRemove, removeLiqValue, tokenA.address, tokenB.address, account])

  const approveCall = useCallback(async () => {
    let address = pairAddress
    let tokenpair = new web3.eth.Contract(pairAbi, address)
    let approveTokenPair = new web3.eth.Contract(ERC20Abi, address)
    const tokenpairDecimal = await tokenpair.methods.decimals().call()
    const approveTokenPairDecimal = await approveTokenPair.methods.decimals().call()
    const diff = tokenpairDecimal - approveTokenPairDecimal

    tokenpair.methods
      .balanceOf(account)
      .call({ from: account })
      .then((result) => {
        let liquidity = toBig(fromExponential(result * Math.pow(10, diff))) // TODO need to check the 1000
        console.log(liquidity)
        approveTokenPair.methods
          .approve(routerAddress, liquidity)
          .send({ from: account })
          .then((ress) => {
            console.log({ ress })
            openSnackbar('Token has been approved!')
            setRemoveLiqValue(0)
          })
          .catch((err) => {
            console.log(err)
            openSnackbar('Approve Failed')
            // _setRemoveLiquidityPriceB(0)
          })
      })
      .catch((err) => {
        console.error(err)
        openSnackbar('Failed!. please  try after 2 mins')
        // setRemoveLiqValue(0)
      })
    // disabled for web3 & openSnackbar
    // eslint-disable-next-line
  }, [account, pairAddress, routerAddress])

  useEffect(() => {
    if (tokenA.address === ZERO_ADDRESS) {
      setApproved(true)
    } else {
      setApproved(false)
    }
  }, [tokenA.address, tokenB.address])

  // load balance for tokenA (liquidity from)
  useEffect(() => {
    if (tokenA.address && account) {
      new Web3Helpers(web3)
        .getBalance(account, tokenA.address)
        .then((_balance) => {
          _setBalanceA(_balance)
        })
        .catch((error) => {
          console.error(error)
          _setBalanceA(0)
        })
    } else {
      _setBalanceA(0)
    }
    // eslint-disable-next-line
  }, [tokenA.address, account])

  // load balance for tokenB (liquidity to)
  useEffect(() => {
    if (tokenB.address) {
      new Web3Helpers(web3)
        .getBalance(account, tokenB.address)
        .then((_balance) => {
          _setBalanceB(_balance)
        })
        .catch((error) => {
          console.error(error)
          _setBalanceB(0)
        })
    } else {
      _setBalanceB(0)
    }
    // eslint-disable-next-line
  }, [tokenB.address, account])

  // refresh token prices
  useEffect(() => {
    getPriceValue(1, 'removeLiquidityPriceA')
  }, [fromAmount, getPriceValue])
  useEffect(() => {
    getPriceValue(1, 'removeLiquidityPriceB')
  }, [toAmount, getPriceValue])

  useEffect(() => {
    const fetchPairAddress = async () => {
      let [addressA, addressB] = smartPath(W_TOKEN_ADDRESS, [tokenA.address, tokenB.address])
      let _pairAddress = await factoryContract.methods.getPair(addressA, addressB).call({ from: account })
      setPairAddress(_pairAddress)
    }
    if (tokenA.address && tokenB.address) {
      fetchPairAddress()
    } else {
      setPairAddress('')
    }
  }, [tokenA.address, tokenB.address, factoryContract, account, W_TOKEN_ADDRESS])

  // load LP token balance
  useEffect(() => {
    const fetch = async () => {
      let tokenpair = new web3.eth.Contract(pairAbi, pairAddress)
      let result = await tokenpair.methods.balanceOf(account).call({ from: account })
      const pairDecimal = await getDecimal(web3.eth, pairAddress)
      let pairBalance = _toFixed(result / 10 ** pairDecimal, 4)
      _setLPTokenValue(pairBalance)
    }

    if (pairAddress && pairAddress !== ZERO_ADDRESS) {
      fetch()
    } else {
      _setLPTokenValue(0)
    }
    // eslint-disable-next-line
  }, [pairAddress, account])

  useEffect(() => {
    const fetchAllowance = async () => {
      let token = new web3.eth.Contract(ERC20Abi, pairAddress)
      let allowance = token.methods.allowance(account, routerAddress).call({ from: account })
      const tokenDecimal = await getDecimal(web3.eth, pairAddress)
      setAllowedToRemove(allowance)
      setRemoveAllowance(allowance / 10 ** tokenDecimal)
    }

    if (pairAddress && pairAddress !== ZERO_ADDRESS) {
      fetchAllowance()
    } else {
      setAllowedToRemove(0)
      setRemoveAllowance(0)
    }
    /* eslint-disable-next-line */ // disabled for web3
  }, [pairAddress, account, routerAddress])

  // loading impact
  useEffect(() => {
    const getPoolShare = async (from, to) => {
      try {
        if (![ZERO_ADDRESS, MAIN_ZERO_ADDRESS].includes(pairAddress)) {
          const fromContract = new web3.eth.Contract(ERC20Abi, from)
          const toContract = new web3.eth.Contract(ERC20Abi, to)
          const fromDecimal = await getDecimal(web3.eth, from)
          const toDecimal = await getDecimal(web3.eth, to)
          const fromBalance = await fromContract.methods.balanceOf(pairAddress).call({ from: account })
          const toBalance = await toContract.methods.balanceOf(pairAddress).call({ from: account })

          const fromShare = fromBalance / Math.pow(10, fromDecimal) || 1
          const toShare = toBalance / Math.pow(10, toDecimal) || 1
          return { fromShare, toShare }
        } else {
          return { fromShare: 1, toShare: 1 }
        }
      } catch (e) {
        return { fromShare: 1, toShare: 1 }
      }
    }

    const fetch = async () => {
      let [tokenAAddress, tokenBAddress] = [tokenA.address, tokenB.address]
      if (tokenAAddress && tokenBAddress) {
        const [from, to] = smartPath(W_TOKEN_ADDRESS, [tokenAAddress, tokenBAddress])
        const shares = await getPoolShare(from, to)
        const _impact = ((+amountA / (shares.fromShare + +amountA)) * 100 + (+amountB / (shares.toShare + +amountB)) * 100) / 2
        _setImpact(_impact)
      }
    }
    fetch()
    // disabled for web3
    // eslint-disable-next-line
  }, [amountA, amountB, tokenA.address, tokenB.address, W_TOKEN_ADDRESS, pairAddress, account])

  // loading totalRatio
  useEffect(() => {
    let tokenAAddress = tokenA.address

    const getReservesRatio = async (short = false) => {
      const pairContract = new web3.eth.Contract(pairAbi, pairAddress)
      const token0 = await pairContract.methods.token0().call({ from: account })
      const reserve = await pairContract.methods.getReserves().call({ from: account })
      if (short) return String(token0).toLowerCase() === String(tokenAAddress).toLowerCase() ? reserve._reserve0 : reserve._reserve1
      return String(token0).toLowerCase() === String(tokenAAddress).toLowerCase() ? reserve._reserve0 / reserve._reserve1 : reserve._reserve1 / reserve._reserve0
    }

    const fetch = async () => {
      if (pairAddress && pairAddress !== ZERO_ADDRESS && pairAddress !== MAIN_ZERO_ADDRESS && tokenAAddress) {
        const ratio = await getReservesRatio()
        _setTokenRatio(+ratio)
      } else {
        _setTokenRatio(0)
      }
    }
    fetch()
    // disabled for web3
    // eslint-disable-next-line
  }, [pairAddress, account, tokenA.address])

  useEffect(() => {
    try {
      getRemoveLiquidityValues()
    } catch (error) {
      console.error(error)
    }
  }, [getRemoveLiquidityValues])

  // this hook used to reset amountA(from liquidity) if dex is changed
  useEffect(() => {
    setAmountA(0)
  }, [routerAddress, factoryAddress, setAmountA])

  // console.log({
  //   tokenA,
  //   tokenB,
  //   amountA,
  //   amountB,
  //   balanceA,
  //   balanceB,
  //   tokenRatio,
  //   impact,
  //   approved,
  //   lpTokenValue,
  //   totalLiqValues,
  //   removeLiquidityPriceA,
  //   removeLiquidityPriceB,
  //   pairAddress,
  //   W_TOKEN_ADDRESS,
  // })

  return {
    tokenA,
    tokenB,
    amountA,
    amountB,
    balanceA,
    balanceB,
    tokenRatio,
    impact,
    approved,
    lpTokenValue,
    totalLiqValues,
    pairAddress,
    removeLiquidityPriceA,
    removeLiquidityPriceB,
    removeLiqValue,
    removeAllowance,
    toAmount,
    fromAmount,
    setRemoveLiqValue,
    approveCall,
    setTokenA,
    setTokenB,
    setAmountA,
    setAmountB,
    addLiquidity,
    removeLiquidity,
    approveLiquidity,
    useMaxAmount,
  }
}

useLiquidity.prototype = {
  options: PropTypes.object,
}
