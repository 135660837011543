import { MAIN_ZERO_ADDRESS, UNISWAP_FACTORY, WETH, ZERO_ADDRESS } from '../constants/addresses'
import Web3Utils from 'web3-utils'
import fromExponential from 'from-exponential'
import factoryAbi from '../abi/factory.json'
import ERC20Abi from '../abi/ERC20.json'
import pairAbi from '../abi/pair.json'

class WebHelpers {
  web3
  abi
  constructor(web3) {
    this.web3 = web3
    this.abi = ERC20Abi
  }
  getDecimal = async (tokenAddress) => {
    try {
      const instance = new this.web3.eth.Contract(this.abi, tokenAddress)
      const decimals = await instance.methods.decimals().call()
      return decimals
    } catch (e) {
      return 18
    }
  }

  findAllowedAmount = async (tokenAddress, amount, account, routerAddress) => {
    try {
      if (tokenAddress === ZERO_ADDRESS) return false
      const decimal = await this.getDecimal(tokenAddress)
      let contract = new this.web3.eth.Contract(this.abi, tokenAddress)
      let allowed = await contract.methods.allowance(account, routerAddress).call()
      allowed = +allowed / Math.pow(10, decimal)
      return allowed < amount
    } catch (error) {
      return true
    }
  }
  compareBalance = async (tokenAddress, amount = 1, account) => {
    const decimal = await this.getDecimal(tokenAddress)
    try {
      const balance = await this.getBalance(account, tokenAddress)
      const available = balance / Math.pow(10, decimal)
      if (available <= amount) return Web3Utils.toBN(balance)
      return Web3Utils.toBN(amount + '0'.repeat(decimal))
    } catch (e) {
      return Web3Utils.toBN(amount + '0'.repeat(decimal))
    }
  }

  getPair = async (from, to, factoryContract = UNISWAP_FACTORY) => {
    try {
      const factory = new this.web3.eth.Contract(factoryAbi, factoryContract)
      const pair = await factory.methods.getPair(from, to).call()
      return pair
    } catch (e) {
      return ZERO_ADDRESS
    }
  }

  getReservesRatio = async (from, pairAddress, account, short = false) => {
    try {
      const tokenPair = new this.web3.eth.Contract(pairAbi, pairAddress)
      const token0 = await tokenPair.methods.token0().call({ from: account })
      const reserve = await tokenPair.methods.getReserves().call({ from: account })
      if (short) return String(token0).toLowerCase() === String(from).toLowerCase() ? reserve._reserve0 : reserve._reserve1
      return String(token0).toLowerCase() === String(from).toLowerCase() ? reserve._reserve0 / reserve._reserve1 : reserve._reserve1 / reserve._reserve0
    } catch (e) {
      return 0
    }
  }

  getImpact = async (from, to, fromAmt, toAmt, factory = UNISWAP_FACTORY, mediator = WETH) => {
    if (!fromAmt || !toAmt) return 0
    if ([MAIN_ZERO_ADDRESS, ZERO_ADDRESS].includes(from)) from = mediator
    if ([MAIN_ZERO_ADDRESS, ZERO_ADDRESS].includes(to)) to = mediator
    let reserveRatio
    fromAmt = Web3Utils.toWei(fromExponential(parseInt(fromAmt)))
    toAmt = Web3Utils.toWei(fromExponential(parseInt(toAmt)))
    const swapFee = 0.25
    const pairAddress = await this.getPair(from, to, factory)
    if (pairAddress !== ZERO_ADDRESS) {
      reserveRatio = await this.getReservesRatio(from, pairAddress)
    } else {
      const pairAB = await this.getPair(from, mediator, factory)
      const pairBC = await this.getPair(mediator, to, factory)
      const ratioAB = await this.getReservesRatio(from, pairAB)
      const ratioBC = await this.getReservesRatio(mediator, pairBC)
      reserveRatio = ratioAB * ratioBC
    }
    const amtRatio = fromAmt / toAmt
    const impact = (1 - reserveRatio / amtRatio) * 100 - swapFee
    // console.log(impact, ' impact')
    return impact < 0 ? 0.00001 : impact
  }

  getBalance = async (account, tokenAddress) => {
    try {
      const balanceContract = new this.web3.eth.Contract(this.abi, tokenAddress)
      const tokenDecimals = await this.getDecimal(tokenAddress)
      let balan = 0
      if (tokenAddress !== ZERO_ADDRESS) {
        balan = await balanceContract.methods.balanceOf(account).call({ from: account })
      } else {
        balan = await this.web3.eth.getBalance(account)
      }
      balan = (balan / Math.pow(10, tokenDecimals)).toFixed(4)
      balan = parseFloat(balan)
      return balan
    } catch (error) {
      console.error(error)
      return 0
    }
  }
}

export default WebHelpers
