import ApolloClient, { InMemoryCache, gql } from 'apollo-boost'

/**
 *
 * @param {String} uri
 */
export const createGraphQLClient = (uri) => {
  return new ApolloClient({
    uri: uri, //[GraphQL endpoint]
    cache: new InMemoryCache(),
  })
}

export const GET_TOKENS_WITH_ADDRESS = gql`
  query GET_TOKENS($first: Int!, $address: ID) {
    tokens(first: $first, where: { id: $address }) {
      address: id
      symbol
      name
      decimals
    }
  }
`

export const GET_TOKENS_WITH_NAME = gql`
  query GET_TOKENS($first: Int!, $name: String) {
    tokens(first: $first, where: { name_starts_with_nocase: $name }) {
      address: id
      symbol
      name
      decimals
    }
  }
`

export const GET_TOKENS = gql`
  query GET_TOKENS($first: Int!, $skip: Int) {
    tokens(first: $first, skip: $skip) {
      address: id
      symbol
      name
      decimals
    }
  }
`
