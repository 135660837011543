import { createContext } from 'react'
import Dexes from '../../public/json/dexes.json'
const DexesData = Dexes.dexes

export const AppGlobalContext = createContext({
  dex: DexesData[0], // DexesData[0] initial dex
  isWrongNetwork: false,
  network: {
    W_TOKEN_ADDRESS: '',
    CHAIN: 0,
    MAIN_TOKEN_ICON: '',
    MAIN_TOKEN_NAME: '',
    HTTP_PROVIDER_URL: '',
  }, // required chain id
})
