import { useMemo } from 'react'
import TokensOfDex from 'Component/popup/SelectToken/TokensOfDex'
import AllTokens from 'Component/popup/SelectToken/AllTokens'

function SelectToken({ isOpen, disabled = [], dismiss, onTokenChainHandler, onlyAvailableTokens = false }) {

  const TokenListView = useMemo(() => {
    if (onlyAvailableTokens) {
      return TokensOfDex
    }
    return AllTokens
  }, [onlyAvailableTokens])

  return <TokenListView isOpen={isOpen} disabled={disabled} dismiss={dismiss} onTokenChainHandler={onTokenChainHandler} />
}

export default SelectToken
