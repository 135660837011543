import { useSnackbar } from 'react-simple-snackbar'

export const defaultOptions = {
  position: 'top-center',
  style: {
    // backgroundColor: 'midnightblue',
    // border: '2px solid lightgreen',
    // color: 'lightblue',
    // fontFamily: 'Menlo, monospace',
    // fontSize: '20px',
    // textAlign: 'center',
  },
  closeStyle: {
    // color: 'lightcoral',
    // fontSize: '16px',
  },
}

export function useAppSnackbar(options) {
  return useSnackbar(options || defaultOptions)
}
