import { DEFAULT_SLIPPAGE_VALUE } from '../../../constants'
import { LOCAL_STORAGE_ITEM_NAMES } from '../../../constants/localStorage'
const initialState = {
  multihops: false,
  slippage: DEFAULT_SLIPPAGE_VALUE,
  deadline: 20, // mins// transaction deadline
}
export const ACTION_TYPES = {
  TOGGLE_MULTIHOPS: 'SETTING/TOGGLE_MULTIHOPS',
  SET_SLIPPAGE: 'SETTING/SET_SLIPPAGE',
  SET_DEADLINE: 'SETTING/SET_DEADLINE',
}

const saveStateToLocalStorage = (_settings) => {
  localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, JSON.stringify(_settings))
}
const loadStateFromLocalStorage = () => {
  const settings = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.SETTINGS)
  return settings ? JSON.parse(settings) : null
}

export default function settingsReducer(state = initialState, action) {
  let newState
  let settings
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_MULTIHOPS:
      newState = { ...state, multihops: !state.multihops }
      saveStateToLocalStorage(newState)
      return newState
    case ACTION_TYPES.SET_SLIPPAGE:
      newState = { ...state, slippage: action.payload }
      saveStateToLocalStorage(newState)
      return newState
    case ACTION_TYPES.SET_DEADLINE:
      newState = { ...state, deadline: action.payload }
      saveStateToLocalStorage(newState)
      return newState
    default:
      settings = loadStateFromLocalStorage()
      if (settings) {
        state = settings
      } else {
        saveStateToLocalStorage(state)
      }
      return state
  }
}
