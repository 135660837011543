import fromExponential from 'from-exponential'
import Web3Utils from 'web3-utils'
import { CHAINS, defiPlus_Ether_Url as baseUrl, URL_ARRAY } from '../constants/index'

// export const GetTVL = async (dexes) => {
//   try {
//     const values = await Promise.all(
//       dexes.map(async (dex) => {
//         const url = baseUrl + dex
//         const response = await fetch(url)
//         const data = await response.json()
//         return data?.market_data?.total_value_locked?.usd || 0
//       })
//     )
//     return [values.reduce((acc, val) => acc + val, 0), values]
//   } catch (err) {
//     return ''
//   }
// }

/**
 *
 * @returns { Promise(Number) }
 */
export const getConnectedChain = async () => {
  const chainId = await window?.ethereum?.request({ method: 'eth_chainId' })
  return Web3Utils.toDecimal(chainId)
}

export const getDefaultChain = async () => {
  const chainId = await window?.ethereum?.request({ method: 'eth_chainId' })
  // console.log(chainId);
  if (chainId === CHAINS.Ropsten || chainId === CHAINS.Mainnet) {
    return 'eth'
  } else if (chainId === CHAINS.BSCTESTNET || chainId === CHAINS.BSCMAINNET) {
    return 'bsc'
  } else if (chainId === CHAINS.FANTOMTESTNET || chainId === CHAINS.FANTOMMAINNET) {
    return 'ftm'
  } else if (chainId === CHAINS.POLYGONMAINNET) {
    return 'polygon'
  } else {
    return 'eth'
  }
}

export const fromExp = (amt, shouldDivide = true) => {
  const divisor = Math.pow(10, 18)
  const parsed = parseFloat(amt) / (shouldDivide ? divisor : 1)
  return fromExponential(parsed)
}

export const getRandom = (arr = [], n = 1, includeCallback = () => {}) => {
  const included = arr.filter(includeCallback)
  const result = new Array(n)
  let len = arr.length,
    taken = new Array(len)

  if (n > len) return arr
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return [...result, ...included]
}

export const checkImage = (imageSrc, good = () => {}, bad = () => {}, defaultImage) => {
  var img = new Image()
  img.onload = () => good(imageSrc)
  img.onerror = () => bad(defaultImage)
  img.src = imageSrc
}

export const generateSecondary = (link) => `https://wispy-bird-88a7.uniswap.workers.dev/?url=http://${link}.link`

export const fetchLink = async (link, setter, errorSetter) => {
  const second = generateSecondary(link)
  console.log(link)
  try {
    const response = await fetch(link.includes('http') ? link : second)
    const data = await response.json()
    if (Array.isArray(data.tokens)) errorSetter(false)
    setter({ data, url: link })
  } catch (err) {
    errorSetter(true)
    setter(null)
  }
}

// export const getTokenBalance = async (tokenAddress, account) => {
// 	let web3 = window.web3;
// 	let bal = '';
// 	console.log(web3, account);
// 	if (web3 && web3.eth && account) {
// 		try {
// 			if(tokenAddress === ZERO_ADDRESS){
// 				bal = await web3.eth.getBalance(account);
// 			}else{
// 				let contract = new web3.eth.Contract(ERC20, tokenAddress);
// 				bal = await contract.methods.balanceOf(account).call();
// 			};
// 		} catch (err) {
// 			console.log(err);
// 		};
// 	};
// 	return bal;
// };

/**
 *
 * @param {address} tokenAddress
 * @param {Number} amount  @description Amount without decimal
 * @returns
 */
// export const compareBalance = async (tokenAddress, amount = LARGE_APPROVAL_AMOUNT, account) => {
// 	const decimal = await getDecimal(tokenAddress);
// 	try{
// 		const balance = await getTokenBalance(tokenAddress, account);
// 		const available = balance / Math.pow(10, decimal);
// 		if(available <= amount) return Web3Utils.toBN(balance);
// 		return Web3Utils.toBN(amount + '0'.repeat(decimal));
// 	}catch(e){
// 		return Web3Utils.toBN(amount + '0'.repeat(decimal));
// 	};
// };

// export const findAllowedAmount = async (address, amount, router, callback = () => {}) => {
//     try {
// 		if(address === ZERO_ADDRESS) return false;
// 		const decimal = await getDecimal(address);
// 		const web3 = window.web3;
// 		let walletAddress = localStorage.getItem('account');
// 		let contract = new web3.eth.Contract(ERC20, address);
// 		let allowed = await contract.methods.allowance(walletAddress, router).call();
// 		console.log(allowed);
// 		allowed = +allowed / Math.pow(10, decimal);
// 		console.log(allowed);
// 		callback(allowed < amount);
// 		return allowed < amount;
//     } catch (error) {
// 		callback(true);
// 		return true;
//     };
// };

export const _toFixed = (num, fixed) => {
  /* eslint-disable-next-line */
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return num.toString().match(re)[0]
}

export const toBig = (num) => Web3Utils.toBN(num)
