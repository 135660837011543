import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Header from './Component/header'
import Gs from './Theme/globalStyles'
import Footer from './Component/footer'
import Tvl from './Component/shared/tvl'
import Dexes from './Pages/Dexes'
import { usePageChain, useWallet } from './hooks'
import { useLayoutEffect } from 'react'

function App() {
  const [pagePadding, setPagePadding] = useState(200)
  const appFooterRef = useRef(null)
  const { isConnected, switchNetwork, active, chainId } = useWallet()
  const appChainId = usePageChain()

  /**
   * @description use to adjust page responsive of footer and header
   */
  const adjustSectionHeights = useCallback(() => {
    const footerHeight = appFooterRef.current?.offsetHeight || appFooterRef.current?.clientHeight
    setPagePadding(footerHeight + 20)
  }, [appFooterRef])

  useLayoutEffect(() => {
    adjustSectionHeights()
    window.addEventListener('resize', adjustSectionHeights)

    return () => {
      window.removeEventListener('resize', adjustSectionHeights)
    }
  }, [adjustSectionHeights])

  useEffect(() => {
    isConnected()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const run = async () => {
      if (active && chainId && chainId !== appChainId) {
        switchNetwork(appChainId)
      }
    }
    let timer = setTimeout(() => {
      run()
    }, 200)

    return () => {
      clearTimeout(timer)
    }
  }, [active, appChainId, chainId, switchNetwork])

  return (
    <>
      {/* basename={'bscswap_newdark_v1'}   */}
      {
        <section
          className='MainBox clearfix'
          style={{
            paddingBottom: pagePadding,
          }}
        >
          <Gs.GlobalStyle />
          <Header />
          <Gs.MainBox>
            <Tvl />
            <Switch>
              <Route path='/' exact render={() => <Redirect to={'/page1?chain=eth'} />} />
              <Route path='/:id'>
                <Dexes />
              </Route>
            </Switch>
          </Gs.MainBox>
          <Footer footerRef={appFooterRef} />
        </section>
      }
    </>
  )
}
// : provider === 'loading' ? <Loader /> : <DetectAlert />

export default App
