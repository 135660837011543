export const ACTION_TYPES = {
  ADD_TOKENS: 'thegraph/ADD_TOKENS',
}
const initialState = {
  tokens: [],
}

export default function thegraphReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.ADD_TOKENS:
      return { ...state, tokens: state.tokens.concat(action.payload) }
    default:
      return state
  }
}
