import Tokens from 'Component/popup/SelectToken/TokensViewUI'
import { useAllEnabledTokens } from 'hooks'

function AllTokens({ isOpen, disabled = [], dismiss, onTokenChainHandler }) {
  const tokens = useAllEnabledTokens()

  return <Tokens tokens={tokens} isOpen={isOpen} disabled={disabled} dismiss={dismiss} onTokenChainHandler={onTokenChainHandler} />
}

export default AllTokens
