// import { LOCAL_STORAGE_ITEM_NAMES } from '../../../constants/localStorage'

const initialState = {
  showConnectWalletModal: false,
}

export const ACTION_TYPES = {
  toggleConnectWalletModal: 'GLOBAL/toggleConnectWalletModal',
}

// const saveStateToLocalStorage = (_newState) => {
//   localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.global, JSON.stringify(_newState))
// }
// const loadStateFromLocalStorage = () => {
//   const state = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.global)
//   return state ? JSON.parse(state) : null
// }

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.toggleConnectWalletModal:
      if (typeof action.payload === 'undefined') {
        return { ...state, showConnectWalletModal: !state.showConnectWalletModal }
      }
      return { ...state, showConnectWalletModal: action.payload }
    default:
      return state
  }
}
