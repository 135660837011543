import { combineReducers } from 'redux'

import tokenListReducer from './tokenList/tokenListSlice'
import settingsReducer from './settings/settingsSlice'
import globalsReducer from './global/globalSlice'
import theGraphReducer from './thegraph/thegraphSlice'
import cutomTokenReducer from './cutomTokens/cutomTokenSlice'

const rootReducer = combineReducers({
  tokenList: tokenListReducer,
  settings: settingsReducer,
  theGraph: theGraphReducer,
  globals: globalsReducer,
  customTokens: cutomTokenReducer,
})

export default rootReducer
