import React from 'react'
import styled from 'styled-components'
import Modal from '../modal'
import ReactTooltip from 'react-tooltip'
import { explorerUrl } from '../../constants/index'

import Xbtn from '../../Assets/images/closeBTN.png'

export default function TransactionPopup(props) {
  // const [grid, setShow] = React.useState();
  const selectToken = () => {
    return localStorage.getItem('transactionHash')
  }
  const transactionLink = () => {
    const link_url = explorerUrl[props.chain] + localStorage.getItem('transactionHash')
    const style = {
      color: 'white',
    }
    return (
      <a style={style} href={link_url || '#'} rel='noreferrer' target='_blank'>
        View transaction
      </a>
    )
  }

  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss} chain={props.chain}>
      <PPMainBx>
        <PPsBx01>
          <PPstitle01>
            Transaction Successfull
            <PPClosBTN01 onClick={props.dismiss} />
          </PPstitle01>
          <PPstitle02>
            <span></span>
          </PPstitle02>

          <PPstitle02 className='overFlowHidden'>
            {selectToken()}
            {/* <button><i className="fas fa-arrow-down"></i> </button> */}
          </PPstitle02>
        </PPsBx01>

        <PPsBx02>
          <button>{transactionLink()}</button>
        </PPsBx02>
      </PPMainBx>

      <ReactTooltip effect='solid' type='light' className='myTip' />
    </Modal>
  )
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const PPMainBx = styled.div`
  width: 100%;
  max-width: 431px;
  margin: 0 auto;
  background-color: #2c2f36;
  border: 2px solid #000;
  display: block;
  border-radius: 10px;
`

const PPClosBTN01 = styled.button`
  width: 20px;
  height: 20px;
  border: 0px;
  outline: none;
  color: #fff;
  background: url(${Xbtn}) left top no-repeat;
  position: absolute;
  right: 0px;
  transition: 0.5s ease all;
  :hover {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

const PPstitle02 = styled(PPstitle01)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  &.overFlowHidden {
    overflow: hidden;
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`
