import React, { useCallback, useState } from 'react'
import { PPClosBTN01, SwapSSbox01, SwapTitle01, TSinputBar, TSinputBar02, YLMBX, YLTitle03 } from './styled'
import Cross from '../Assets/images/close-icon.png'
import { useSelector, useDispatch } from 'react-redux'
import { toggleMultiHops, setSlippage, setDeadlineValue } from '../redux/roducers/settings/settingsActions'
import { DEFAULT_SLIPPAGE_VALUE } from '../constants/index'
import { numberFormat } from '../utils/new'

const TransactionSettingBox = ({ onClickClose }) => {
  const dispatch = useDispatch()
  const { slippage, multihops, deadline } = useSelector((state) => state.settings)

  const handleClickAutoBtn = useCallback(() => {
    dispatch(setSlippage(DEFAULT_SLIPPAGE_VALUE))
  }, [dispatch])

  const handleMultihops = useCallback(() => {
    dispatch(toggleMultiHops())
  }, [dispatch])

  const onChangeSlippage = useCallback(
    (e) => {
      let value = numberFormat(e.target.value)
      if (parseFloat(value) > 100) {
        value = 100
      }
      if (parseFloat(value) < 0) {
        value = DEFAULT_SLIPPAGE_VALUE
      }
      dispatch(setSlippage(value))
    },
    [dispatch]
  )

  const handleDeadlineValue = useCallback(
    (v) => {
      dispatch(setDeadlineValue(+v))
    },
    [dispatch]
  )

  return (
    <SwapSSbox01>
      <SwapTitle01 className='smTitle'>
        <div className='close-icon' onClick={onClickClose}>
          <button title=''>
            <img src={Cross} alt='' width='22' height='25' />
          </button>
        </div>
        <span>Transaction settings</span>
        <PPClosBTN01 />
      </SwapTitle01>
      <YLMBX className='v2'>
        <YLTitle03>
          Slippage tolerance
          <i className='fas helpIco fa-question-circle' data-tip='Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.' />
        </YLTitle03>
        <TSinputBar>
          <button className='tsBTN01' onClick={handleClickAutoBtn}>
            Auto
          </button>
          <input id='slippage' type='text' placeholder='0' value={String(slippage)} onChange={onChangeSlippage} />
          <span>%</span>
        </TSinputBar>
        <div>
          {slippage >= 50 ? (
            <span
              style={{
                paddingTop: '20px',
                color: '#f00',
              }}
            >
              Enter a valid slippage percentage
            </span>
          ) : (
            <></>
          )}
          {slippage >= 6 && slippage < 50 ? (
            <span
              style={{
                paddingTop: '20px',
                color: '#aa0',
              }}
            >
              Your transaction may be frontrun
            </span>
          ) : (
            <></>
          )}
          {slippage === 0 ? (
            <span
              style={{
                paddingTop: '20px',
                color: '#aa0',
              }}
            >
              Your transaction may fail
            </span>
          ) : (
            <></>
          )}
        </div>
        <YLTitle03>
          Transaction deadline
          <i className='fas helpIco fa-question-circle' data-tip='Your transaction will revert if it is left confirming for longer than this time.'></i>
        </YLTitle03>
        <TSinputBar className='smbar'>
          <input type='number' placeholder='' value={deadline} onChange={(e) => handleDeadlineValue(e.target.value)} />
          minutes
        </TSinputBar>
        <YLTitle03>
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}> Interface settings </span>
        </YLTitle03>
        {/* !!! dont remove. we will use in future */}
        {/* <TSinputBar02>
                <span>Toggle expert mode<i className="fas helpIco fa-question-circle" data-tip='Bypasses confirmation modals and allows high slippage trades. Use at your own risk.'></i></span>
                <span>
                    <div className="apollo-element apollo-element-active apollo-field-switcher">
                        <div className="apollo-fieldset">
                            <label>
                                <input type="checkbox" name="shortcode[active]" value="1" data-depend-id="active" data-atts="active"
                                    onClick={handleExpert} checked={expertMode}
                                />
                                <em data-on="On" data-off="Off"></em><span></span>
                            </label>
                        </div>
                    </div>
                </span>
            </TSinputBar02> */}
        <TSinputBar02>
          <span>
            Disable multihops<i className='fas helpIco fa-question-circle' data-tip='Restricts swaps to direct pairs only.'></i>
          </span>
          <span>
            <div className='apollo-element apollo-element-active apollo-field-switcher'>
              <div className='apollo-fieldset'>
                <label>
                  <input
                    type='checkbox'
                    name='multihops[active]'
                    value='1'
                    data-depend-id='active'
                    data-atts='active'
                    checked={multihops}
                    onChange={handleMultihops}
                    // disabled={toAddress == '' ? true : false}
                  />
                  <em data-on='On' data-off='Off'></em>
                  <span></span>
                </label>
              </div>
            </div>
          </span>
        </TSinputBar02>
      </YLMBX>
      {/* <YLBtnBar>
            <span> Don't see the pool you joined? <a href="#" className="ylBTN01">Import it</a> </span>
        </YLBtnBar> */}
    </SwapSSbox01>
  )
}

export default TransactionSettingBox
