import { ACTION_TYPES } from './settingsSlice'

export const toggleMultiHops = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.TOGGLE_MULTIHOPS,
  })
}

/**
 *
 * @param {Number} value
 * @returns
 */
export const setSlippage = (value) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SLIPPAGE,
    payload: value,
  })
}

/**
 *
 * @param {Number} value // mins
 * @returns
 */
export const setDeadlineValue = (value) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEADLINE,
    payload: value,
  })
}
