import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import dexIcon01 from './../Assets/images/dexIcon-01.png'
import dexIcon02 from './../Assets/images/dexIcon-02.png'
import dexIcon03 from './../Assets/images/dexIcon-03.png'
import dexIcon04 from './../Assets/images/dexIcon-04.png'
import dexIcon05 from './../Assets/images/dexIcon-05.png'
import dexIcon06 from './../Assets/images/dexIcon-06.png'
import dexIcon07 from './../Assets/images/dexIcon-07.png'
import dexIcon08 from './../Assets/images/dexIcon-08.png'
import dexIcon09 from './../Assets/images/dexIcon-09.png'
import dexIcon010 from './../Assets/images/dexIcon-010.png'
import dexIcon011 from './../Assets/images/dexIcon-011.png'
import dexIcon012 from './../Assets/images/dexIcon-012.png'
import dexIcon013 from './../Assets/images/dexIcon-013.png'
import dexIcon014 from './../Assets/images/dexIcon-014.png'
import dexIcon015 from './../Assets/images/dexIcon-015.png'
import dexIcon016 from './../Assets/images/dexIcon-016.png'
import dexIcon017 from './../Assets/images/dexIcon-017.png'
import dexIcon018 from './../Assets/images/dexIcon-018.png'
import dexIcon019 from './../Assets/images/dexIcon-019.png'
import { IconsCenterbox } from './shared/common'

const ICONS = [
  {
    icon: dexIcon01,
    alt: 'dexIcon01',
    href: '#',
  },
  {
    icon: dexIcon02,
    alt: 'dexIcon02',
    href: '#',
  },
  {
    icon: dexIcon03,
    alt: 'dexIcon03',
    href: '#',
  },
  {
    icon: dexIcon04,
    alt: 'dexIcon04',
    href: '#',
  },
  {
    icon: dexIcon05,
    alt: 'dexIcon05',
    href: '#',
  },
  {
    icon: dexIcon06,
    alt: 'dexIcon06',
    href: '#',
  },
  {
    icon: dexIcon07,
    alt: 'dexIcon07',
    href: '#',
  },
  {
    icon: dexIcon08,
    alt: 'dexIcon08',
    href: '#',
  },
  {
    icon: dexIcon09,
    alt: 'dexIcon09',
    href: '#',
  },
  {
    icon: dexIcon010,
    alt: 'dexIcon010',
    href: '#',
  },
  {
    icon: dexIcon011,
    alt: 'dexIcon011',
    href: '#',
  },
  {
    icon: dexIcon012,
    alt: 'dexIcon012',
    href: '#',
  },
  {
    icon: dexIcon013,
    alt: 'dexIcon013',
    href: '#',
  },
  {
    icon: dexIcon014,
    alt: 'dexIcon014',
    href: '#',
  },
  {
    icon: dexIcon015,
    alt: 'dexIcon015',
    href: '#',
  },
  {
    icon: dexIcon016,
    alt: 'dexIcon016',
    href: '#',
  },
  {
    icon: dexIcon017,
    alt: 'dexIcon017',
    href: '#',
  },
  {
    icon: dexIcon018,
    alt: 'dexIcon018',
    href: '#',
  },
  {
    icon: dexIcon019,
    alt: 'dexIcon019',
    href: '#',
  },
]

class AllDex extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <>
        <IconsCenterbox>
          <span>Supporting DEXs:</span>
          <div className='icon-list'>
            {ICONS.map((e, _) => (
              <Link key={_} to={e.href}>
                <img alt={e.alt} src={e.icon} />
              </Link>
            ))}
          </div>
        </IconsCenterbox>
      </>
    )
  }
}

export default AllDex
