import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Media from 'Theme/media-breackpoint'
import fromExponential from 'from-exponential'
import { usePageChain, useWallet } from './../hooks'
import { useDispatch } from 'react-redux'
import { CHAIN_NAME_BY_ID, NETWORK_ICONS, REACT_APP_DYNAMIC_SWAP_URL } from '../constants'
// import { NetworkSwitchAlert } from './detectAlert'
import { toggleConnectWalletModal } from '../redux/roducers/global/globalActions'
import { WrongNetworkBtn } from './shared/common'
import { AppButton } from './shared/Buttons'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, DropdownMenu } from './shared/Dropdown'

const navs = [
  {
    text: 'DYNAMIC DEX',
    to: '',
    externalLink: REACT_APP_DYNAMIC_SWAP_URL,
    dataTip: '',
  },
  {
    text: 'STATIC DEX',
    to: '#',
    externalLink: 'https://multiswap.derex.exchange',
    dataTip: 'Coming Soon',
  },
  {
    text: 'IBO',
    to: '',
    externalLink: `${REACT_APP_DYNAMIC_SWAP_URL}/ibo/launch-pools`,
    dataTip: '',
  },
  {
    text: 'BONDS OTC',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
  {
    text: 'ROI BOOSTER',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
  {
    text: 'INDEX FUNDS',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
  {
    text: 'dSPAC',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
]

const getAddress = (account) => account.substr(0, 6) + '...' + account.substr(-4).toUpperCase()
const getAccountBal = (bal) => {
  return bal === 0
    ? bal.toFixed(3)
    : bal.toFixed(3) > 0
    ? bal.toFixed(3)
    : bal.toFixed(4) > 0
    ? bal.toFixed(4)
    : bal.toFixed(5) > 0
    ? bal.toFixed(5)
    : bal.toFixed(6) > 0
    ? bal.toFixed(6)
    : bal.toFixed(3)
}


const RenderLink = ({ nav, className = '' }) => {
  const { pathname } = useLocation()
  const active = useMemo(() => {
    return pathname === nav.to || (nav.reg && pathname.search(nav.reg) > -1)
  }, [nav.reg, nav.to, pathname])

  return nav.externalLink ? (
    <a href={nav.externalLink} className={`header-app-link ${className}`} target='_blank' rel='noreferrer'>
      {nav.text}
    </a>
  ) : (
    <Link to={nav.to} className={`header-app-link ${className} ${active ? 'active' : ''}`} data-type='light' data-html='true' data-class='data-tooltip' data-tip={nav.dataTip}>
      {nav.text}
    </Link>
  )
}

function Header() {
  const [balance, setBalance] = useState(0)
  const { active, account, chainId, library, switchNetwork, disconnectWallet } = useWallet()
  const dispatch = useDispatch()
  const chain = usePageChain()
  const chainName = CHAIN_NAME_BY_ID[chainId]
  const { pathname } = useLocation()

  const networkIcon = useMemo(() => NETWORK_ICONS[chainId], [chainId])
  // const isWrongNetwork = useMemo(() => chainName !== chain, [chainName, chain])

  // const networkSwitchAlertElt = useMemo(
  //   () => (
  //     <Modal isOpen={active && isWrongNetwork}>
  //       <NetworkSwitchAlert text={chain} />
  //     </Modal>
  //   ),
  //   [active, isWrongNetwork, chain]
  // )

  useEffect(() => {
    if (library) {
      library
        .getBalance(account)
        .then((balance) => {
          const bal = fromExponential(balance) * Math.pow(10, -18) // default decimal // TODO need to check the decimal
          setBalance(getAccountBal(bal))
        })
        .catch(() => {
          setBalance(0)
        })
    } else {
      setBalance(0)
    }
  }, [library, account])

  return (
    <>
      {/* {networkSwitchAlertElt} */}
      <MainheadboxWrap>
        <Mainheadbox>
          <Logo href='/' className='z-index-100'>
            <img src={'/images/DEREX-logo.png'} alt='' />
          </Logo>
          <HeadCenterboxV2 className='z-index-50'>
            {navs.map((nav, index) => (
              <RenderLink key={index} nav={nav} />
            ))}
          </HeadCenterboxV2>
          {/* <HeadCenterboxV2>
              <ul>
                  <li>fdfd</li>
                </ul>
            </HeadCenterboxV2> */}
          {!active ? (
            <SwapBTN01 className='outline-gray fs-2md z-index-100 white-space-nowrap' onClick={() => dispatch(toggleConnectWalletModal())}>
              Connect Wallet
            </SwapBTN01>
          ) : (
            <HeadRightbox className='z-index-100'>
              <Dropdown className='dropdown mr-20'>
                <button className='btn bg-none dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                  More
                </button>
                <DropdownMenu className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                  {navs.map((nav, index) => (
                    <li key={index} className='py-5'>
                      <RenderLink className='dropdown-item' key={index} nav={nav} />
                    </li>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {chainName === chain ? (
                <>
                  <span>{balance}</span>
                  <CurrIcon className='p-0'>
                    <img src={networkIcon} alt='Network Icon' />
                  </CurrIcon>
                  <WalletAddress onClick={disconnectWallet} data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Disconnect Wallet'}>
                    {getAddress(account)}
                  </WalletAddress>
                </>
              ) : (
                <>
                  <span>{balance}</span>
                  <CurrIcon className='mr-2'>
                    <WrongNetworkBtn onClick={() => switchNetwork(chain)} />
                  </CurrIcon>
                  <WalletAddress>Network</WalletAddress>
                </>
              )}
              {/* <WalletSignal   /> */}
              <Dotsignals>
                <ul className='signals'>
                  <li className='dot-lg'></li>
                  <li className='dot-md'></li>
                  <li className='dot-sme'></li>
                </ul>
              </Dotsignals>
            </HeadRightbox>
          )}
        </Mainheadbox>
      </MainheadboxWrap>
    </>
  )
}

const MainheadboxWrap = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.headbg02};
  z-index: 100;
`

const Logo = styled.a`
  overflow: hidden;
  img {
    object-fit: contain;
    height: auto;
    min-width: 100%;
  }
`

const HeadRightbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .header-app-link:hover {
    color: ${({theme}) => theme.colorPrimary};
  }
`

const SwapBTN01 = styled(AppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: #ababab;
  width: 20%;
  padding: 12px 12px;
  border-radius: 10px;
  max-width: 200px;

  @media (max-width: 767px) {
    &.darkGray {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    width: auto;
    margin: 10px 0;
    &.darkGray {
      font-size: 14px;
    }
  }
`

const CurrIcon = styled.button`
  color: #fff;
  // padding: 8px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 38px;
  height: 38px;
`

const WalletAddress = styled.div`
  color: ${(props) => props.theme.color01};
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  @media (max-width: 575px) {
    font-size: 12px;
  }
`
const HeadCenterboxV2 = styled.div`
  width: 80%;
  // max-width: 1250px;
  margin: 0 auto;
  display: flex;
  color: #fff;
  justify-content: center;
  position: absolute;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colorLightGrey};
    padding: 6px 12px 6px 20px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    :hover,
    &.active {
      color: #8be05a;
      &:after {
        color: #fff;
      }
    }
    // &:after{ content:'/'; font:italic 18px/30px arial; position:absolute; left:8px; top:0px;  }
  }
  ${Media.sm} {
    align-items: center;
    flex-direction: column;
  }
  ${Media.xs} {
    width: 100%;
    flex-direction: row;

    a {
      font-size: 12px;
      padding: 0px 7px;
    }
  }
`
const Dotsignals = styled.div`
  .dot-lg,
  .dot-md,
  .dot-sme {
    display: inline-block;
    background: #8ee057;
    border-radius: 50%;
  }
  .dot-lg {
    width: 8px;
    height: 8px;
  }
  .dot-md {
    width: 5px;
    height: 5px;
  }
  .dot-sme {
    width: 3px;
    height: 3px;
  }
  .dot-sme::marker {
    content: '';
  }
  .signals {
    padding: 0 0 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .signals li {
    margin-bottom: 4px;
  }
`

const Mainheadbox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 85px;
  z-index: 100;
  padding: 0 15px;

  ${HeadRightbox} > .dropdown {
    display: none;
    & .dropdown-menu > li {
      display: none;
    }
  }

  ${Media.xl} {
    ${HeadCenterboxV2} > .header-app-link:nth-last-child(-n + 4) {
      display: none;
    }
    ${HeadRightbox} > .dropdown {
      display: block;

      & .dropdown-menu > li:nth-last-child(-n + 4) {
        display: block;
      }
    }
  }
  ${Media.lg} {
    ${HeadCenterboxV2} > .header-app-link:nth-last-child(-n + 5) {
      display: none;
    }
    ${HeadRightbox} > .dropdown .dropdown-menu > li:nth-last-child(-n + 5) {
      display: block !important;
    }
  }
  ${Media.md} {
    ${HeadCenterboxV2} > .header-app-link {
      display: none;
    }
    ${HeadRightbox} > .dropdown .dropdown-menu > li {
      display: block !important;
    }
  }

  ${Media.xs} {
    align-items: center;
  }
`

export default Header
