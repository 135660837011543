import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Tokens from 'Component/popup/SelectToken/TokensViewUI'
import { AppGlobalContext } from 'contexts'
import { createGraphQLClient, GET_TOKENS, GET_TOKENS_WITH_ADDRESS, GET_TOKENS_WITH_NAME } from 'services/thegraph'
import { useAllTokens } from 'hooks'
import Web3Utils from 'web3-utils'

const LOAD_COUNT = 50

function TokensOfDex({ isOpen, disabled = [], dismiss, onTokenChainHandler }) {
  const [tokens, setTokens] = useState([])
  const { dex } = useContext(AppGlobalContext)
  const [hasMore, setHasMore] = useState(false)
  const allTokensFromApi = useAllTokens()
  const client = useMemo(() => {
    return createGraphQLClient(dex.theGraphUri)
  }, [dex.theGraphUri])

  //   add label property and avator of token
  const fillProperties = useCallback(
    (_tokens) => {
      _tokens = _tokens.map((item) => {
        let sameToken = allTokensFromApi.find((item1) => item1.address.toLowerCase() === item.address.toLowerCase())
        let image = sameToken?.image || sameToken?.logoURI
        return { ...item, label: item.symbol, image }
      })
      return _tokens
    },
    [allTokensFromApi]
  )

  const onSearchToken = useCallback(
    /**
     *
     * @param {String} search  @description Symbol name or
     */
    async (search) => {
      // search by address
      if (Web3Utils.isAddress(search)) {
        const { data } = await client.query({
          query: GET_TOKENS_WITH_ADDRESS,
          variables: {
            first: LOAD_COUNT,
            address: search,
          },
        })
        const loadedTokens = fillProperties(data.tokens)
        setTokens(loadedTokens)
      } else {
        // search by symbol
        const { data } = await client.query({
          query: GET_TOKENS_WITH_NAME,
          variables: {
            first: LOAD_COUNT,
            name: search,
          },
        })
        const loadedTokens = fillProperties(data.tokens)
        setTokens(loadedTokens)
      }
    },
    [client, fillProperties]
  )
  const onLoadMore = useCallback(async () => {
    console.log('onLoadMore...')
    if (hasMore) {
      const { data } = await client.query({
        query: GET_TOKENS,
        variables: {
          first: LOAD_COUNT,
          skip: tokens.length,
        },
      })
      setHasMore(data.tokens?.length === LOAD_COUNT)
      if (data.tokens) {
        const loadedTokens = fillProperties(data.tokens)
        setTokens((pTokens) => [...pTokens, ...loadedTokens])
      } else {
        console.log('data: ', data)
      }
    } else {
      console.log('there is no more tokens')
    }
  }, [client, hasMore, tokens.length, fillProperties])

  const fetchInitialTokens = useCallback(async () => {
    const { data } = await client.query({
      query: GET_TOKENS,
      variables: {
        first: LOAD_COUNT,
      },
    })

    return data.tokens || []
  }, [client])

  //   fetch initial tokens
  useEffect(() => {
    const run = async () => {
      let tokens = await fetchInitialTokens()
      //   add label property and avator of token
      tokens = fillProperties(tokens)
      setTokens(tokens)
      setHasMore(tokens.length === LOAD_COUNT)
    }
    run()
  }, [fetchInitialTokens, fillProperties])

  return <Tokens tokens={tokens} isOpen={isOpen} disabled={disabled} dismiss={dismiss} onTokenChainHandler={onTokenChainHandler} onSearchToken={onSearchToken} onLoadMore={onLoadMore} />
}

export default TokensOfDex
