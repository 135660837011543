import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

function Tabs({ children, activedTabIndex }) {
  const activedTab = useMemo(() => {
    return children.find((child) => child.props.tabIndex === activedTabIndex)
  }, [children, activedTabIndex])
  return <div>{activedTab}</div>
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
}

export default Tabs
