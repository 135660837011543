import React, { useContext, useEffect, useState } from 'react'
import { Errortxt, GreenButton, SSbx01, SSbx02, SwapBTN01, SwapInputMbx, SwapSSbox01, SwapTitle01, SwapTitle02, SwapTitle03, SwapInputTitle } from '../styled'
import { useWallet } from '../../hooks'
import AppToolTip from '../AppToolTip'
import { useDispatch } from 'react-redux'
import { toggleConnectWalletModal } from '../../redux/roducers/global/globalActions'
import { ZERO_ADDRESS } from '../../constants/addresses'
import { AppGlobalContext } from '../../contexts'
import { TokenIcon, WrongNetworkBtn } from '../shared/common'
import { DebounceInput } from 'react-debounce-input'
import PropTypes from 'prop-types'

function LiquidityInput({ title, value, onChange, disabled, isWrongNetwork, token, balance, onClickToken, onClickMax }) {
  return (
    <SwapInputMbx>
      <SwapInputTitle className='w-full'>
        <span>{title}</span>
        <span>Balance: {balance}</span>
      </SwapInputTitle>
      <SSbx01 className='w-full'>
        <DebounceInput
          element='input'
          debounceTimeout={400}
          type='number'
          placeholder='0.0'
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E') {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <SSbx02 className='pl-5'>
          {token.name !== 'Select Token' && typeof onClickMax === 'function' ? (
            <GreenButton className='green-btn' onClick={onClickMax}>
              Max
            </GreenButton>
          ) : (
            <></>
          )}
          {isWrongNetwork ? (
            <WrongNetworkBtn iconSize={40} size='30px' text='Network' />
          ) : (
            <button className={token.name !== 'Select Token' ? '' : 'OrangeBTN'} onClick={onClickToken}>
              {token.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={token.image} /> : <></>}
              {token.name} <i className='fas fa-chevron-down'></i>
            </button>
          )}
        </SSbx02>
      </SSbx01>
    </SwapInputMbx>
  )
}

LiquidityInput.prototype = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isWrongNetwork: PropTypes.bool.isRequired,
  token: PropTypes.object.isRequired,
  balance: PropTypes.number.isRequired,
  onClickToken: PropTypes.func.isRequired,
  onClickMax: PropTypes.func,
}

function AddLiquidity({
  tokenA,
  tokenB,
  amountA,
  amountB,
  balanceA,
  balanceB,
  lpTokenValue,
  totalLiqValues,
  approved,
  impact,
  pairAddress,
  tokenRatio,
  onSelectTokenA,
  onSelectTokenB,
  onClickAdd,
  onClickApprove,
  onClickMax,
  onChangeInputAmount,
  onChangeToAmount,
}) {
  const { active } = useWallet()
  const dispatch = useDispatch()
  const { isWrongNetwork } = useContext(AppGlobalContext)

  const [isFirstProvider, setIsFirstProvider] = useState(false)
  /**
   * @description This hook is used to show or hide "first provider" message.
   * @description we need to delay it because pairtoken is changed after 0.1 or more.
   */
  useEffect(() => {
    const show = tokenA.address && tokenB.address && (pairAddress === '' || pairAddress === ZERO_ADDRESS)

    let timer
    if (show) {
      setTimeout(() => {
        setIsFirstProvider(show)
      }, [1000])
    } else {
      setIsFirstProvider(show)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [pairAddress, tokenA.address, tokenB.address])

  return (
    <div>
      <SwapSSbox01 className='hover:border-highlight-shadow-primary'>
        <SwapTitle01>
          {/* <img src={TitleIcon01} alt="" /> */}
          Add Liquidity {/* <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i> */}
        </SwapTitle01>
        {isFirstProvider && (
          <Errortxt className='red-color'>
            <p className='bold-txt red-color'>You are first liquidity provider</p>
            <p className='red-color'>The ratio of the tokens you add will set the price of this pool. Once you are happy with the rate click supply to review.</p>
            {/* <p className='red-color'>
              To list your BP20 token as a default token on BSCswap, kindly visit
              <a className='red-color' href='https://bscswap.com/listing'>
                https://bscswap.com/listing
              </a>
            </p> */}
          </Errortxt>
        )}
        <LiquidityInput
          title='Input'
          value={amountA}
          onChange={onChangeInputAmount}
          disabled={isWrongNetwork || isFirstProvider}
          isWrongNetwork={isWrongNetwork}
          balance={balanceA}
          onClickMax={onClickMax}
          onClickToken={onSelectTokenA}
          token={tokenA}
        />
        <SwapTitle02 className='Center'>
          <i className='fas fa-plus'></i>
        </SwapTitle02>
        <LiquidityInput
          title='To (estimated)'
          value={amountB}
          onChange={onChangeToAmount}
          disabled={isWrongNetwork || isFirstProvider}
          isWrongNetwork={isWrongNetwork}
          balance={balanceB}
          onClickToken={onSelectTokenB}
          token={tokenB}
        />
        <SwapTitle03>
          {![tokenB.name, tokenA.name].includes('Select Token') ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: '10px 0',
                alignItems: 'flex-start',
                borderRadius: '10px',
                border: '.1rem solid #555',
              }}
            >
              <span
                style={{
                  margin: '0',
                  fontSize: '12px',
                  padding: '10px',
                  borderBottom: '.1rem solid #555',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                Prices and pool share
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '15px 10px',
                  width: '100%',
                }}
              >
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {!tokenRatio ? '-' : (1 / tokenRatio).toFixed(4)}
                  </span>
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {tokenB.name} per {tokenA.name}
                  </span>
                </section>
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {!tokenRatio ? '-' : tokenRatio.toFixed(4)}
                  </span>
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {tokenA.name} per {tokenB.name}
                  </span>
                </section>
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {(impact || 0) === 0 ? '-' : impact < 0.0001 ? '<0.0001%' : impact >= 100 ? '100%' : `${impact.toFixed(4)}%`}
                  </span>
                  <span
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    Share of Pool
                  </span>
                </section>
              </div>
            </div>
          ) : (
            <div style={{ margin: '10px' }} />
          )}
        </SwapTitle03>
        {pairAddress && pairAddress !== ZERO_ADDRESS ? (
          active ? (
            +balanceA < +amountA ? (
              <SwapBTN01 className='darkGray' disabled>
                Insufficient Funds
              </SwapBTN01>
            ) : approved ? (
              <SwapBTN01 className='orangBack' onClick={onClickAdd}>
                Add Liquidity
              </SwapBTN01>
            ) : (
              <SwapBTN01 className='orangBack' onClick={onClickApprove}>
                Approve {tokenA.name}
              </SwapBTN01>
            )
          ) : (
            <SwapBTN01 className='hover:darkGray' onClick={() => dispatch(toggleConnectWalletModal())}>
              Connect Wallet
            </SwapBTN01>
          )
        ) : (
          <SwapBTN01 className='hover:darkGray' disabled>
            Invalid Pair
          </SwapBTN01>
        )}
      </SwapSSbox01>
      {/* end of addLiquidity */}
      {![tokenB.name, tokenA.name].includes('Select Token') ? (
        <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'column',
            width: '100%',
            margin: '10px 0',
            alignItems: 'flex-start',
            borderRadius: '10px',
            border: '.1rem solid #555',
          }}
        >
          <span
            style={{
              color: '#aaa',
              margin: '10px 0',
            }}
          >
            LP Tokens in your wallet
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: '#aaa',
              width: '100%',
              marginBottom: '5px',
            }}
          >
            <span>
              <span>
                {tokenA.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenA.image} /> : <></>}
                {tokenB.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenB.image} /> : <></>}
              </span>
              {tokenA.name}/{tokenB.name} LP
            </span>
            <span
              style={{
                fontWeight: '600',
                color: '#ddd',
              }}
            >
              {lpTokenValue}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: '#aaa',
              width: '100%',
              marginBottom: '5px',
            }}
          >
            <span>
              {tokenA.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenA.image} /> : <></>}
              Pooled {tokenA.name}
            </span>
            <span
              style={{
                fontWeight: '600',
                color: '#ddd',
              }}
            >
              {(Number(totalLiqValues.from) || 0).toFixed(4)}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: '#aaa',
              width: '100%',
              marginBottom: '5px',
            }}
          >
            <span>
              {tokenB.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={tokenB.image} /> : <></>}
              Pooled {tokenB.name}
            </span>
            <span
              style={{
                fontWeight: '600',
                color: '#ddd',
              }}
            >
              {(Number(totalLiqValues.to) || 0).toFixed(4)}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <AppToolTip />
    </div>
  )
}

export default AddLiquidity
