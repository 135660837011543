import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Modal from 'Component/modal'
import { IoIosClose } from 'react-icons/io'
import { BsPinAngleFill, BsPinAngle } from 'react-icons/bs'
import ManageToken from 'Component/popup/manageToken'
import Xbtn from 'Assets/images/closeBTN.png'
import ERC20 from 'abi/ERC20.json'
import defaultIcon from 'Assets/images/defaultIcon.svg'
import AppToolTip from 'Component/AppToolTip'
import { ZERO_ADDRESS } from 'constants/addresses'
import { setUsePoolTokens } from 'redux/roducers/tokenList/tokenActions'
import { BASE_TOKENS, commonBasesTokens, LOAD_MORE_COUNT } from 'constants/index'
import { useCommonTokens, useWallet, useOnScreen, usePageChain, useWeb3, useCustomTokens } from 'hooks'
import CustomScrollbars from 'Component/shared/CustomScrollbars'
import { TokenIcon } from 'Component/shared/common'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

function SelectTokenPopup({ isOpen, disabled = [], tokens, dismiss, onTokenChainHandler, onSearchToken, onLoadMore }) {
  const boxRef = useRef(null)
  const [allOptions, setAllOptions] = useState([])
  const [options, setOptions] = useState([])
  const { account } = useWallet()
  const [balArray, setBalArray] = useState([])
  const [openManage, setOpenManage] = useState(false)
  const [query, setQuery] = useState('')
  const [isDev] = useState(process.env.REACT_APP_MODE === 'development')
  const [renderKey, setRenderKey] = useState(Math.random())
  const web3 = useWeb3()
  const chain = usePageChain()
  const { localTokens: customTokens } = useCustomTokens(chain)
  const allTokens = useMemo(() => {
    return [...customTokens, ...tokens]
  }, [customTokens, tokens])
  const { commonBases, add: addToCommon, remove: removeFromCommon } = useCommonTokens()
  const commonTokens = useMemo(() => {
    return allTokens.filter((t) => commonBases.includes(t.address || t.value))
  }, [commonBases, allTokens])

  const [showMoreTokens, lastRef] = useOnScreen({
    threshold: 0.1,
  })

  const { usePoolTokens } = useSelector((state) => state.tokenList)
  const dispatch = useDispatch()

  const addTokenToCommonBases = useCallback(
    (e, token) => {
      e.stopPropagation()
      addToCommon(token.address)
    },
    [addToCommon]
  )

  const removeTokenToCommonBases = useCallback(
    (e, token) => {
      e.stopPropagation()
      removeFromCommon(token.address)
    },
    [removeFromCommon]
  )

  const belongToCommonBase = useCallback(
    (token) => {
      return commonBases.includes(token.value || token.address)
    },
    [commonBases]
  )

  const loadMoreTokens = useCallback(() => {
    setOptions((p) => allTokens.slice(0, p.length + LOAD_MORE_COUNT))
  }, [allTokens])

  const searchTokens = useCallback(
    (keyword) => {
      if (!isDev) {
        const filterList = (eve) => {
          setOptions(
            allTokens.filter(
              (e) => e.label.toLowerCase().startsWith(eve.toLowerCase()) || e.value?.toLowerCase().startsWith(eve.toLowerCase()) || e.address?.toLowerCase().startsWith(eve.toLowerCase())
            )
          )
        }
        if (keyword) {
          filterList(keyword)
        } else {
          const currentList = allTokens.slice(0, LOAD_MORE_COUNT)
          setOptions(currentList)
        }
      } else {
        const filterList = (eve) => {
          setOptions((o) => o.filter((e) => e.label.toLowerCase().startsWith(eve.toLowerCase())))
        }
        filterList(keyword)
      }
    },
    [allTokens, isDev]
  )

  const onChangeUsePoolTokenHandler = useCallback(
    (e) => {
      dispatch(setUsePoolTokens(e.target.checked))
      e.preventDefault()
      e.stopPropagation()
    },
    [dispatch]
  )

  useEffect(() => {
    if (allOptions?.length && account) {
      Promise.all(
        allOptions.map(async (el) => {
          try {
            const { value } = el
            let bal = ''
            const isWallet = value === ZERO_ADDRESS
            const contract = new web3.eth.Contract(ERC20, value)
            if (!isWallet) {
              bal = await contract.methods.balanceOf(account).call({ from: account })
            } else {
              bal = await web3.eth.getBalance(account)
            }
            bal = (bal / 10 ** 18).toFixed(4)
            return { address: value, bal }
          } catch (err) {
            return { address: el.value, balance: 0 }
          }
        })
      )
        .then((opts) => setBalArray(opts))
        .catch((e) => setBalArray([]))
    }
  }, [allOptions, account, web3.eth])

  useEffect(() => {
    if (isDev) {
      const opts = BASE_TOKENS[chain]
      addToCommon(opts.map((o) => o.value || o.address))
    } else {
      const currentList = (l) => allTokens.slice(0, l.length + LOAD_MORE_COUNT)
      setOptions(currentList)
      setAllOptions(allTokens)
      const commonTokens = allTokens.filter((t) => commonBasesTokens[chain]?.includes(t.symbol))
      addToCommon(commonTokens.map((v) => v.address || v.value))
    }
  }, [chain, isDev, allTokens, addToCommon])

  useEffect(() => {
    const desired = customTokens.filter((el) => chain === el.chain)
    const altered = desired.map((el) => ({ ...el, values: el.address }))
    const original = (o) => o.filter((el) => !el.custom)
    setOptions((o) => [...original(o), ...altered])
    setAllOptions((o) => [...original(o), ...altered])
  }, [customTokens, chain])

  // search token by symbol or address
  useEffect(() => {
    let timer = setTimeout(() => {
      if (typeof onSearchToken === 'function') {
        onSearchToken(query)
      } else {
        searchTokens(query)
      }
    }, 200)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [query])

  useEffect(() => {
    const handleClick = (e) => {
      if (boxRef?.current && !boxRef?.current?.contains(e.target) && isOpen) {
        setQuery('')
        setRenderKey(Math.random())
        dismiss()
      }
    }

    if (openManage) window.removeEventListener('click', handleClick)
    else window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [boxRef, isOpen, openManage, dismiss])

  // show more tokens by moving down scrollbar
  useEffect(() => {
    loadMoreTokens()
    // eslint-disable-next-line
  }, [showMoreTokens])

  // load more by api
  useEffect(() => {
    let timer = setTimeout(() => {
      if (typeof onLoadMore === 'function' && options.length === tokens.length && tokens.length !== 0) {
        onLoadMore()
      }
    }, 200)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [options])

  return (
    <Modal
      key={renderKey}
      isOpen={isOpen}
      disableCloseBtn
      dismiss={() => {
        setRenderKey(Math.random())
        setQuery('')
        dismiss()
      }}
    >
      <PPMainBx ref={boxRef}>
        <ManageToken
          // coinList={coinList}
          // setCoinList={setCoinList}
          isOpen={openManage}
          dismiss={() => {
            setOpenManage(false)
            setRenderKey(Math.random())
            dismiss()
          }}
          partialDismiss={() => setOpenManage(false)}
        />
        <PPsBx01>
          <PPstitle01>
            Select token 
            <i className='fas helpIco fa-question-circle' data-tip='Type in a token name or smart contract address'></i>
            <PPClosBTN01
              onClick={() => {
                setQuery('')
                setRenderKey(Math.random())
                dismiss()
              }}
            />
          </PPstitle01>
          <PopInput01 placeholder='Search by symbol or token name' onChange={(e) => setQuery(e.target.value)} value={query} />
          <PPstitle02>
            <span>
              Common bases<i className='fas helpIco fa-question-circle' data-tip='Popular pools across the DeFi landscape'></i>
            </span>
          </PPstitle02>
          <PPselectTokenBTN>
            {commonTokens.map((b, idx) => (
              <button
                className={disabled.includes(b.label) ? 'active' : ''}
                onClick={() => {
                  if (disabled.includes(b.label)) return
                  setQuery('')
                  onTokenChainHandler({ name: b.label, values: b.value || b.address, image: b.image || defaultIcon })
                }}
                key={idx}
                data-delay-show='2000'
                data-tip={b.name}
              >
                <ImgBx>
                  <TokenIcon className='w-full h-full' src={b.image} alt='' />
                </ImgBx>{' '}
                {b.label}
                <IoIosClose className='close-icon' onClick={(e) => removeTokenToCommonBases(e, b)} />
              </button>
            ))}
          </PPselectTokenBTN>
          <PPstitle02>
            <h2 className='fs-12 mb-0'>Token name</h2>
            <FlexDiv>
              {/* <label className='mr-10 d-flex align-items-center' data-tip='Only Pool Tokens'>
                <input value={usePoolTokens} checked={usePoolTokens} type='checkbox' className='text-primary mr-5' aria-label='Only Pool Tokens' onChange={onChangeUsePoolTokenHandler} />
                Only Pool Tokens
              </label> */}
              <button>
                <i className='fas fa-arrow-down'></i>
              </button>
            </FlexDiv>
          </PPstitle02>
        </PPsBx01>
        <CustomScrollbars style={{ width: '100%', height: '385px', position: 'relative' }}>
          {options &&
            options.map((value, index) => {
              // return <option value={options[label].value} >{options[label].label}</option>
              return (
                <PPListtoken
                  key={`token-option-${index}`}
                  style={disabled.includes(value.label) ? { cursor: 'not-allowed', color: '#777' } : {}}
                  onClick={() => {
                    if (disabled.includes(value.label)) return
                    setQuery('')
                    onTokenChainHandler({ name: value.label, values: value.value || value.address, image: value.image || defaultIcon })
                  }}
                  data-delay-show='2000'
                  data-tip={value.name}
                >
                  <ImgBx>
                    <TokenIcon src={value.image} alt='' />
                  </ImgBx>
                  {value.label || value.name}
                  {value.addedByMe ? <span style={{ fontSize: '10px', margin: '7.5px 0 0 7.5px', color: '#aaa' }}>Added by user</span> : <></>}
                  <i>{value.balance || balArray.find((e) => e.address === value.value)?.bal || '-'}</i>
                  {/* {belongToCommonBase(value) ? (
                    <BsPinAngleFill className='ml-5' onClick={(e) => removeTokenToCommonBases(e, value)} />
                  ) : (
                    <BsPinAngle className='ml-5' onClick={(e) => addTokenToCommonBases(e, value)} />
                  )} */}
                </PPListtoken>
              )
            })}
          {!options.length ? <NoMore>No results found</NoMore> : <></>}
          {!isDev && !query && allTokens.length > options.length ? (
            <MoreBlock
              ref={lastRef}
              className='OrangeBTN'
              // onClick={() => {setIsVisible(v => !v)}}
            />
          ) : (
            <></>
          )}
        </CustomScrollbars>

        <PPsBx02
          onClick={() => {
            setOpenManage(true)
          }}
        >
          {/* <button>Having trouble finding a token ?</button> */}
          <button>Manage token lists</button>
        </PPsBx02>
      </PPMainBx>
      <AppToolTip />
    </Modal>
  )
}

SelectTokenPopup.prototype = {
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.array,
  tokens: PropTypes.array.isRequired,
  dismiss: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
  onSearchToken: PropTypes.func,
}

function SelectTokenViewUI({ ...props }) {
  const elt = useMemo(() => <SelectTokenPopup {...props} />, [props])
  return <>{elt}</>
}

export default SelectTokenViewUI

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const PPMainBx = styled.div`
  width: 100%;
  max-width: 431px;
  margin: 0 auto;
  background-color: #16191e;
  border: 2px solid #000;
  display: block;
  border-radius: 10px;
`

const PPClosBTN01 = styled.button`
  width: 20px;
  height: 20px;
  border: 0px;
  outline: none;
  color: #fff;
  background: url(${Xbtn}) left top no-repeat;
  position: absolute;
  right: 0px;
  transition: 0.5s ease all;
  :hover {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #8be05a;

    :hover {
      opacity: 0.7;
    }
  }
`

const PPselectTokenBTN = styled(FlexDiv)`
  justify-content: flex-start;
  width: 100%;
  padding: 8px 0;

  button {
    position: relative;
    display: inline-flex;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #545861;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 10px 4px 6px;
    margin: 0 8px 8px 0;

    :hover {
      background-color: #545861;
    }

    &.active {
      background-color: #1f2127;
      border-color: #1f2127;
    }
    &:hover {
      .close-icon {
        display: block;
      }
    }
    .close-icon {
      position: absolute;
      top: -7px;
      right: -7px;
      background: black;
      border-radius: 50%;
      font-weight: bold;
      display: none;
      z-index: 10;
    }
  }
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

const PPstitle02 = styled(PPstitle01)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 400;

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`

const PopInput01 = styled.input`
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: normal;
  background-color: transparent;
  border: 2px solid #545861;
  width: 100%;
  padding: 11px 8px;
  border-radius: 10px;
  margin: 10px 0;
`

const PPListtoken = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #fff;
  padding: 15px 30px;

  & i {
    margin-left: auto;
  }

  :hover {
    background-color: #24272c;
  }
`

const ImgBx = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 12px;
  border-radius: 15px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
`

const MoreBlock = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;

  &.OrangeBTN {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 6px;

    .fas {
      font-size: 14px;
    }
  }
`

const NoMore = styled.p`
  text-align: center;
`
