import React from 'react'
import { Link } from 'react-router-dom'
import chain1 from '../Assets/images/chains/chain-1.png'
import chain2 from '../Assets/images/chains/chain-2.png'
import chain3 from '../Assets/images/chains/chain-3.png'
import chain4 from '../Assets/images/chains/chain-4.png'
import chain5 from '../Assets/images/chains/chain-5.png'
import chain6 from '../Assets/images/chains/chain-6.png'
import chain7 from '../Assets/images/chains/chain-7.png'
import chain8 from '../Assets/images/chains/chain-8.png'
import chain9 from '../Assets/images/chains/chain-9.png'
import chain10 from '../Assets/images/chains/chain-10.png'
import chain11 from '../Assets/images/chains/chain-11.png'
import chain12 from '../Assets/images/chains/chain-12.png'
import chain13 from '../Assets/images/chains/chain-13.png'
import chain14 from '../Assets/images/chains/chain-14.png'
import chain15 from '../Assets/images/chains/chain-15.png'
import chain16 from '../Assets/images/chains/chain-16.png'
import chain17 from '../Assets/images/chains/chain-17.png'
import chain18 from '../Assets/images/chains/chain-18.png'
import chain19 from '../Assets/images/chains/chain-19.png'
import chain20 from '../Assets/images/chains/chain-20.png'
import chain21 from '../Assets/images/chains/chain-21.png'
import chain22 from '../Assets/images/chains/chain-22.png'

import { IconsCenterbox } from './shared/common'

const ICONS = [
  {
    icon: chain1,
    href: '#',
    alt: 'chain1',
  },
  {
    icon: chain2,
    href: '#',
    alt: 'chain2',
  },
  {
    icon: chain3,
    href: '#',
    alt: 'chain3',
  },
  {
    icon: chain4,
    href: '#',
    alt: 'chain4',
  },
  {
    icon: chain5,
    href: '#',
    alt: 'chain5',
  },
  {
    icon: chain6,
    href: '#',
    alt: 'chain6',
  },
  {
    icon: chain7,
    href: '#',
    alt: 'chain7',
  },
  {
    icon: chain8,
    href: '#',
    alt: 'chain8',
  },
  {
    icon: chain9,
    href: '#',
    alt: 'chain9',
  },
  {
    icon: chain10,
    href: '#',
    alt: 'chain10',
  },
  {
    icon: chain11,
    href: '#',
    alt: 'chain11',
  },
  {
    icon: chain12,
    href: '#',
    alt: 'chain12',
  },
  {
    icon: chain13,
    href: '#',
    alt: 'chain13',
  },
  {
    icon: chain14,
    href: '#',
    alt: 'chain14',
  },
  {
    icon: chain15,
    href: '#',
    alt: 'chain15',
  },
  {
    icon: chain16,
    href: '#',
    alt: 'chain16',
  },
  {
    icon: chain17,
    href: '#',
    alt: 'chain17',
  },
  {
    icon: chain18,
    href: '#',
    alt: 'chain18',
  },
  {
    icon: chain19,
    href: '#',
    alt: 'chain19',
  },
  {
    icon: chain20,
    href: '#',
    alt: 'chain20',
  },
  {
    icon: chain21,
    href: '#',
    alt: 'chain21',
  },
  {
    icon: chain22,
    href: '#',
    alt: 'chain22',
  },
]

function AllBlockchains() {
  return (
    <IconsCenterbox>
      <span>Supporting Blockchains:</span>
      <div className='icon-list'>
        {ICONS.map((e, _) => (
          <Link key={_} to={e.href}>
            <img alt={e.alt} src={e.icon} />
          </Link>
        ))}
      </div>
    </IconsCenterbox>
  )
}

export default AllBlockchains
