const initialState = {
  items: [], // items // urls
  itemData: [], // <ItemIndex>: [tokens] // response
  usePoolTokens: false,
}

export const ACTION_TYPES = {
  set: 'TOKENS/set',
  setItem: 'TOKENS/setItem',
  add: 'TOKENS/add',
  init: 'TOKENS/init',
  setItems: 'TOKENS/ITEMS/setItems',
  updateItem: 'TOKENS/ITEMS/updateItem',
  setUsePoolTokens: 'TOKENS/ITEMS/setUsePoolTokens',
}

export default function globalReducer(state = initialState, action) {
  let tokens, origin, newToken, items, newItem, value
  switch (action.type) {
    case ACTION_TYPES.set:
      return { ...state, itemData: action.payload }
    case ACTION_TYPES.add:
      return { ...state, itemData: [...state.itemData, action.payload] }
    case ACTION_TYPES.init:
      return { ...state, itemData: [] }
    case ACTION_TYPES.setItem:
      tokens = state.itemData.slice()
      origin = tokens[action.payload.index]
      newToken = { ...origin, ...action.payload.tokens }
      tokens[action.payload.index] = action.payload.tokens
      return { ...state, itemData: tokens }
    case ACTION_TYPES.setItems:
      return { ...state, items: action.payload }
    case ACTION_TYPES.updateItem:
      items = state.items.slice()
      origin = items[action.payload.index]
      newItem = { ...origin, ...action.payload.item }
      items[action.payload.index] = newItem
      return { ...state, items: items }
    case ACTION_TYPES.setUsePoolTokens:
      value = action.payload
      return { ...state, usePoolTokens: value }
    default:
      return state
  }
}
