import React, { useCallback, useMemo } from 'react'
import DexesJSON from '../../public/json/dexes.json'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SwapPage from './SwapPage'
import { AppGlobalContext } from '../contexts/index'
import { CHAINID_BY_NAME, NETWORKS } from '../constants/index'
// import NetworkSwitch from '../Component/NetworkSwitch'
import ConnectWallet from '../Component/popup/connectWallet'
import { toggleConnectWalletModal } from '../redux/roducers/global/globalActions'
import { useWallet, usePageChain } from '../hooks'
const DexesData = DexesJSON.dexes

const Dexes = () => {
  const { id } = useParams()
  const chain = usePageChain()
  const dispatch = useDispatch()
  const { active, chainId: connectedChain } = useWallet()
  const { showConnectWalletModal } = useSelector((state) => state.globals)
  const chainId = CHAINID_BY_NAME[chain] // chain id in Number

  const dex = DexesData.find((e) => e.id === id)

  const handleDismiss = useCallback(() => {
    dispatch(toggleConnectWalletModal(false))
  }, [dispatch])

  const network = NETWORKS.find((n) => n.CHAIN === chainId)
  const isWrongNetwork = useMemo(() => active && chainId !== connectedChain, [active, chainId, connectedChain])

  return (
    <>
      <AppGlobalContext.Provider value={{ dex: dex, network: network, isWrongNetwork: isWrongNetwork }}>
        <SwapPage dex={dex} />
      </AppGlobalContext.Provider>
      <ConnectWallet isOpen={showConnectWalletModal} dismiss={handleDismiss} />
    </>
  )
}

export default Dexes
