import React from 'react'
import { ConnectWallText, PPClosBTN01, SwapBTN01, SwapInputMbx, SwapSSbox01, SwapTitle01, SwapTitle05 } from '../styled'
import Cross from '../../Assets/images/close-icon.png'
import AppToolTip from '../AppToolTip'

function ManageLiquidity({ onClickClose, onClickAddLiquidity }) {
  return (
    <SwapSSbox01 className={'hover:border-highlight-shadow-primary'} id='manage-liquidity'>
      <SwapTitle01>
        {/* <img src={TitleIcon01} alt="" /> */}
        <div className='close-icon' onClick={onClickClose}>
          <a href='#A' title=''>
            <img src={Cross} alt='' width='22' height='25' />
          </a>
        </div>
        Add Liquidity <PPClosBTN01 />
      </SwapTitle01>
      <SwapBTN01 className='primary' onClick={onClickAddLiquidity}>
        Add Liquidity
      </SwapBTN01>
      <SwapTitle05>
        Your liquidity <i className='fas helpIco fa-question-circle' data-tip='View, add, or remove your LP tokens by connecting your wallet'></i>
      </SwapTitle05>
      <SwapInputMbx>
        <ConnectWallText> Connect to a wallet to view your liquidity </ConnectWallText>
      </SwapInputMbx>
      {/* <SSLinkbox01>
                Don't see the pool you joined?  <a >Import it</a>
            </SSLinkbox01> */}
      <AppToolTip />
    </SwapSSbox01>
  )
}

export default ManageLiquidity
