import React, { useContext, useMemo } from 'react'
import { FlexDiv, SwapSSbox01, SwapTitle01, YLBtnBar, YLMBX, YLTitle01, YLTitle02 } from '../styled'
import { generatePairExplorerUrl } from '../../utils/new'
import { usePageChain, useWallet } from '../../hooks/index'
import { ZERO_ADDRESS } from '../../constants/addresses'
import AppToolTip from '../AppToolTip'
import { AppGlobalContext } from '../../contexts'
import { CloseBtn } from '../shared/common'

function YourLiquidity({ tokenA, tokenB, poolToken, onClickAdd, onClickRemove, liqValues, pairAddress, onClickSelectTokenA, onClickSelectTokenB, onClickClose = () => { } }) {
  const chain = usePageChain()
  const { isWrongNetwork } = useContext(AppGlobalContext)
  const pairTokenUrl = useMemo(() => generatePairExplorerUrl(chain, pairAddress), [pairAddress, chain])
  const { active } = useWallet()

  return (
    <SwapSSbox01 className={'hover:border-highlight-shadow-primary'} id='your-liquidity'>
      <SwapTitle01 className='smTitle right-iconwraper'>
        <CloseBtn onClick={onClickClose} />
        <span>
          Your Liquidity<i className='fas helpIco fa-question-circle' data-tip='Your liquidity on the selected DEX'></i>
        </span>
      </SwapTitle01>
      <YLMBX>
        <YLTitle01>
          <span>
            <i>
              <img src={tokenA.image} alt='' />
            </i>
            <i>
              <img src={tokenB.image} alt='' />
            </i>
          </span>
          {tokenA.name}/{tokenB.name}
        </YLTitle01>
        <YLTitle02>
          Pooled {tokenA.name}:
          <FlexDiv>
            {/* <span>{liqValues.from}</span> */}
            <button onClick={onClickSelectTokenA}>
              <i>
                <img src={tokenA.image} alt='' />
              </i>
            </button>
          </FlexDiv>
        </YLTitle02>
        <YLTitle02>
          Pooled {tokenB.name}:
          <FlexDiv>
            {/* <span>{`${liqValues.to} `}</span> */}
            <button onClick={onClickSelectTokenB}>
              <i>
                <img src={tokenB.image} alt='' />
              </i>
            </button>
          </FlexDiv>
        </YLTitle02>
        <YLTitle02>
          Your pool tokens: <span>{poolToken} </span>
        </YLTitle02>
        <YLTitle02>Your pool shared:</YLTitle02>
        <YLBtnBar>
          <a href={pairTokenUrl} target='_blank' className='ylBTN01' rel='noreferrer' data-tip={!pairAddress || pairAddress === ZERO_ADDRESS ? 'Pair does not exist' : ''}>
            View pool information <i className='fas fa-external-link-alt'></i>
          </a>
        </YLBtnBar>
        <YLBtnBar>
          <button className='ylBTN02' onClick={onClickAdd}>
            Add
          </button>
          <button
            className={!Number(poolToken) ? 'ylBTN02' : 'ylBTN03'}
            // style={!Number(poolToken) ? { cursor: 'not-allowed' } : {}}
            // disabled={!Number(poolToken)}
            data-tip={isWrongNetwork ? 'Please switch your network' : ''}
            onClick={isWrongNetwork || !active ? () => { } : onClickRemove}
          >
            Remove
          </button>
        </YLBtnBar>
      </YLMBX>
      {/* <YLBtnBar>
        <span>
            Don't see the pool you joined? <a href="#" class="ylBTN01">Import it</a> </span>
    </YLBtnBar> */}
      <AppToolTip />
    </SwapSSbox01>
  )
}

export default YourLiquidity
