import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SSbx01, SSbx02, SwapBTN01, SwapInputMbx, SwapSSbox01, SwapTitle01, SwapTitle02, SwapTitle03, YellowButton } from './styled'
import SelectTokenPopup from 'Component/popup/SelectToken/index'
import { AppGlobalContext } from '../../contexts/index'
import { SelectType, useSwap, useWallet } from '../../hooks'
import { ZERO_ADDRESS } from '../../constants/addresses'
import { defaultToken } from '../../constants/index'
import { useSelector, useDispatch } from 'react-redux'
import { toggleConnectWalletModal } from '../../redux/roducers/global/globalActions'
import { TokenIcon, WrongNetworkBtn } from '../shared/common'
import { numberFormat } from '../../utils/new'
import { DebounceInput } from 'react-debounce-input'
import { ImSpinner10 } from 'react-icons/im'

const SwapInput = ({ title, value, onChange, isWrongNetwork, token, onClickToken, onClickMax = () => {} }) => {
  return (
    <SwapInputMbx>
      <div className='w-full swap-title'>
        <span>{title}</span>
      </div>
      <SSbx01>
        <DebounceInput
          debounceTimeout={400}
          element='input'
          type='text'
          value={value}
          placeholder='0.0'
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E') {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          onChange={onChange}
          disabled={isWrongNetwork}
        />
        <SSbx02>
          {/* <span>Balance:{this.state.balanceto}</span> */}
          {token.address ? (
            <YellowButton className='d-none' onClick={onClickMax}>
              Max
            </YellowButton>
          ) : (
            <></>
          )}
          {isWrongNetwork ? (
            <WrongNetworkBtn iconSize={40} size='30px' text='Network' />
          ) : (
            <button className={token.name !== 'Select Token' ? '' : 'OrangeBTN'} onClick={onClickToken}>
              {token.name !== 'Select Token' ? <TokenIcon className='token-icon' alt='' src={token.image} /> : <></>}
              {token.name} <i className='fas fa-chevron-down'></i>
            </button>
          )}
        </SSbx02>
      </SSbx01>
    </SwapInputMbx>
  )
}

function Swap({ defaultTokenA = defaultToken, defaultTokenB = defaultToken, onChangePairToken }) {
  const { active, account } = useWallet() // to amount
  const { dex, network, isWrongNetwork } = useContext(AppGlobalContext)
  const [selectType, setSelectType] = useState(0) // determine to change tokenA or tokenB
  const [showSelectToken, setShowSelectToken] = useState(false) // to token
  const [disableSwapButton, setDisableSwapButton] = useState(false) // to token
  const { multihops, slippage, deadline } = useSelector((state) => state.settings)
  const dispatch = useDispatch()
  const { usePoolTokens } = useSelector((state) => state.tokenList)

  const {
    tokenA,
    tokenB,
    amountA,
    amountB,
    pairAddress,
    approved,
    balanceA,
    priceImpact,
    isRefreshingAmountOut,
    handleChangeAmountA,
    swap,
    handleChangeAmountB,
    setTokenA,
    setTokenB,
    approve,
    switchTokens,
    refreshOutAmount,
  } = useSwap({
    W_TOKEN_ADDRESS: network.W_TOKEN_ADDRESS,
    router: dex.router,
    factoryAddress: dex.factory,
    IAgentAddress: network.IAgentAddress,
    account: account,
    options: {
      defaultTokenA,
      defaultTokenB,
      slippageValue: slippage,
      transactionDeadline: deadline,
    },
  })

  const onClickSelectToken = useCallback((type) => {
    setSelectType(type)
    setShowSelectToken(true)
  }, [])

  const onTokenChainHandler = useCallback(
    (_token) => {
      if (selectType === SelectType.TokenA) {
        setTokenA(
          {
            name: _token.name,
            address: _token.values,
            image: _token.image,
          },
          selectType
        )
      } else {
        setTokenB(
          {
            name: _token.name,
            address: _token.values,
            image: _token.image,
          },
          selectType
        )
      }
      setShowSelectToken(false)
    },
    [selectType, setTokenA, setTokenB]
  )

  useEffect(() => {
    setTokenA({
      name: network.MAIN_TOKEN_NAME,
      address: ZERO_ADDRESS,
      image: network.MAIN_TOKEN_ICON,
    })
    setTokenB(defaultToken)
    // disabled for setTokenA
    // eslint-disable-next-line
  }, [network])

  useEffect(() => {
    if (pairAddress === ZERO_ADDRESS && multihops) {
      setDisableSwapButton(true)
    } else {
      setDisableSwapButton(false)
    }
    if (typeof onChangePairToken === 'function') {
      onChangePairToken(pairAddress)
    }
  }, [pairAddress, multihops, onChangePairToken])
  // if hook help to reset amountA(from token value) and amountB(to token value) when dex is changed by user.
  useEffect(() => {
    // handleChangeAmountA(0)
    refreshOutAmount(tokenA.address, tokenB.address, amountA, SelectType.TokenA)
    // eslint-disable-next-line
  }, [dex.router, dex.factory])

  /**
   * realtime update for swap section.
   * refresh AmountOut every 4 secs
   */
  useEffect(() => {
    let timer = setInterval(() => {
      if (tokenA.address && tokenB.address && amountA > 0) {
        refreshOutAmount(tokenA.address, tokenB.address, amountA, SelectType.TokenA)
      }
    }, 4000)

    return () => {
      clearInterval(timer)
    }
    // dex.router, dex.factory are added for dex chagne
    // if dex is changed by user, this hook will be called.
    // eslint-disable-next-line
  }, [amountA, tokenA.address, tokenB.address, dex.router, dex.factory])

  return (
    <>
      <SwapSSbox01 className='hover:border-highlight-shadow-primary'>
        <SwapTitle01> {/* <img src={TitleIcon02} alt='' /> */} Swap </SwapTitle01>
        <SwapInput
          title='From'
          value={String(amountA)}
          onChange={(e) => {
            let value = numberFormat(e.target.value)
            handleChangeAmountA(value)
          }}
          isWrongNetwork={isWrongNetwork}
          token={tokenA}
          onClickToken={() => onClickSelectToken(SelectType.TokenA)}
        />
        <SwapTitle02 className='Center'>
          <i className='fas fa-arrow-down' onClick={switchTokens}></i>
        </SwapTitle02>
        <SwapInput
          title='To (estimated)'
          value={String(amountB)}
          onChange={(e) => {
            let value = numberFormat(e.target.value)
            handleChangeAmountB(value)
          }}
          isWrongNetwork={isWrongNetwork}
          token={tokenB}
          onClickToken={() => onClickSelectToken(SelectType.TokenB)}
        />
        {isRefreshingAmountOut ? (
          <div className='mt-10 ml-10'>
            <ImSpinner10 className='spin-16' size={20} />
          </div>
        ) : null}
        <SwapTitle03>
          {parseFloat(priceImpact) ? (
            <span
              style={{
                color: '#777',
                width: '100%',
                fontSize: '12.5px',
                paddingTop: '5px',
                textAlign: 'left',
              }}
            >
              Price Impact: {priceImpact < 0.0001 ? '<0.0001' : priceImpact.toFixed(4)}%
            </span>
          ) : (
            <></>
          )}
        </SwapTitle03>
        {active ? (
          +balanceA > +amountA ? (
            approved ? (
              <SwapBTN01
                className='orangBack py-16'
                onClick={() => {
                  try {
                    swap()
                  } catch (error) {
                    console.error(error)
                  }
                }}
                disabled={disableSwapButton || slippage >= 50}
              >
                Swap
              </SwapBTN01>
            ) : (
              <SwapBTN01 className='OrangeBTN' onClick={approve}>
                Approve {tokenA.name}
              </SwapBTN01>
            )
          ) : (
            <SwapBTN01 className='darkGray' disabled>
              Insufficient Funds
            </SwapBTN01>
          )
        ) : (
          <SwapBTN01 className='darkGray' onClick={() => dispatch(toggleConnectWalletModal())}>
            Connect Wallet
          </SwapBTN01>
        )}
        {multihops ? <p>Multihops is on please off for transaction!</p> : null}
      </SwapSSbox01>
      <SelectTokenPopup
        isOpen={showSelectToken}
        disabled={[tokenA.name, tokenB.name]}
        onTokenChainHandler={onTokenChainHandler}
        dismiss={() => setShowSelectToken(false)}
        onlyAvailableTokens={usePoolTokens}
      />
    </>
  )
}

export default Swap
