import { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Axios from 'axios'
import Web3 from 'web3'
import { AppGlobalContext } from '../contexts'
import { useWallet } from './useWallet'
import { CHAINID_BY_NAME, URL_ARRAY } from '../constants'
import { generateSecondary } from '../services/data_service'
import { setItem, setItems, updateItem } from '../redux/roducers/tokenList/tokenActions'
import { setCustomTokens, addCutomToken, removeCustomTokens } from '../redux/roducers/cutomTokens/cutomTokenActions'

// hook that binds intersection observer
export const useOnScreen = (options) => {
  const [ref, setRef] = useState(null)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options)

    if (ref) {
      observer.observe(ref)
    }

    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [options, ref])

  return [visible, setRef, ref]
}

export const useWeb3 = () => {
  const {
    network: { HTTP_PROVIDER_URL },
  } = useContext(AppGlobalContext)
  const { library } = useWallet() // if wallet is connected

  const web3 = useMemo(() => {
    return new Web3(library?.provider || HTTP_PROVIDER_URL)
  }, [library?.provider, HTTP_PROVIDER_URL])
  return web3
}

export const usePageChain = () => {
  const { search } = useLocation()
  const chain = new URLSearchParams(search).get('chain')
  return chain
}

/**
 *
 * @param {String} chain  'eth' | 'bsc' | 'polygon'
 * @returns
 */
export const useStorage = (chain) => {
  const dispatch = useDispatch()
  const { items, itemData } = useSelector((state) => state.tokenList) // coin list
  // get items saved or save and return if does not exist
  const getCoinList = useCallback(() => {
    const _coinList = localStorage.getItem('coinList')
    let parsed
    try {
      parsed = JSON.parse(_coinList)
      const networkName = Object.keys(parsed)[0]
      const firstData = parsed[networkName][0].data
      if (!firstData) throw new Error('Incorrect format!')
      return parsed
    } catch (e) {
      localStorage.setItem('coinList', JSON.stringify(URL_ARRAY))
      return URL_ARRAY
    }
  }, [])

  const refreshToken = useCallback(
    async (_chain, _item, index) => {
      const chainId = CHAINID_BY_NAME[_chain]
      try {
        const url = _item.data.includes('http') ? _item.data : generateSecondary(_item.data)
        const { data } = await Axios.get(url)
        let res = data

        if (Array.isArray(res.tokens)) {
          res.tokens = (res?.tokens || []).filter((el) => parseInt(el.chainId) === chainId).map((el) => ({ ...el, image: el.logoURI, value: el.address, label: el.symbol }))
        } else {
          // if res.tokens is object
          let tokens = []
          for (const [key, value] of Object.entries(res.tokens)) {
            tokens.push({
              chain: _chain,
              ...value,
              image: value.logoURI,
              value: value.address,
              label: value.symbol,
            })
          }
          res.tokens = tokens.sort((a, b) => a.symbol.toLowerCase() > b.symbol.toLowerCase() || -1)
        }
        dispatch(setItem(index, res))
      } catch (e) {
        dispatch(setItem(index, []))
      }
    },
    [dispatch]
  )

  const setVisible = useCallback(
    /**
     *
     * @param {Number} index @description Index of Token
     * @param {Boolean} enabled
     */
    (index, enabled = false) => {
      if (typeof index === 'number') {
        dispatch(updateItem(index, { enabled: enabled }))
        let _coinlist = getCoinList()
        _coinlist[chain][index].enabled = enabled
        try {
          localStorage.setItem('coinList', JSON.stringify(_coinlist))
        } catch (error) {
          localStorage.removeItem('coinList')
          window.location.reload()
        }
      }
    },
    [dispatch, getCoinList, chain]
  )

  const loadTokens = useCallback(() => {
    for (let i = 0; i < items.length; i++) {
      const element = items[i]
      refreshToken(chain, element, i)
    }
  }, [chain, items, refreshToken])

  const initList = useCallback(() => {
    const list = getCoinList()
    const _list = list[chain] || []
    dispatch(setItems(_list))
  }, [chain, getCoinList, dispatch])

  return { items, itemData, setVisible, loadTokens, initList }
}

// return allTokens(from APIs) that are filtered by chain
export const useAllTokens = () => {
  const { itemData } = useSelector((state) => state.tokenList) // supported tokens list @type Array
  let tokens = useMemo(() => {
    let _tokens = itemData
      .map((item) => item.tokens)
      .flat()
      .filter((token) => token)
    // remove duplicated tokens
    // _tokens = _tokens.filter((token, index, self) => {
    //   return self.findIndex((t) => String(t.address).toLowerCase() === String(token.address).toLowerCase()) === index
    // })
    return _tokens
  }, [itemData])
  return tokens
}

// returns tokens(From enabled APIS) that are filtered by chain
export const useAllEnabledTokens = () => {
  const { items, itemData } = useSelector((state) => state.tokenList) // supported tokens list @type Array
  const enabledIndexes = useMemo(() => {
    let temp = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.enabled) {
        temp.push(i)
      }
    }
    return temp
  }, [items])

  let mergedTokens = useMemo(() => {
    let allTokens = itemData
      .filter((item, index) => enabledIndexes.includes(index))
      .map((item) => item.tokens)
      .flat(1)
      .filter((item) => item)
    // filter duplicated tokens
    if (allTokens) {
      allTokens = allTokens.filter((token, index, self) => {
        return self.findIndex((t) => String(t.address).toLowerCase() === String(token.address).toLowerCase()) === index
      })
      return allTokens
    } else {
      return []
    }
    // add missing tokens
  }, [enabledIndexes, itemData])
  return mergedTokens || []
}

// save imported token by user, to localStorage and load from it.
/**
 *
 * @param {'eth' | 'bsc' | String} chain
 * @returns
 */
export const useCustomTokens = (chain) => {
  const { customTokens } = useSelector((state) => state.customTokens)
  const dispatch = useDispatch()

  const localTokens = useMemo(() => {
    if (chain) {
      return customTokens.filter((t) => String(t.chain).toLowerCase() === String(chain).toLocaleLowerCase())
    } else {
      return customTokens
    }
  }, [customTokens, chain])

  const setTokens = useCallback(
    (tokens) => {
      return dispatch(setCustomTokens(tokens))
    },
    [dispatch]
  )
  const addToken = useCallback(
    (tokens) => {
      return dispatch(addCutomToken(tokens))
    },
    [dispatch]
  )
  const removeToken = useCallback(
    (address) => {
      return dispatch(removeCustomTokens(address))
    },
    [dispatch]
  )

  return {
    localTokens,
    setTokens,
    addToken,
    removeToken,
  }
}
