var Media = {
  xxl: '@media(max-width:1600px)',
  xl: '@media(max-width:1440px)',
  lg: '@media(max-width:1200px)',
  md: '@media(max-width:992px)',
  md2: '@media(max-width:800px)',
  sm: '@media(max-width:768px)',
  xs: '@media(max-width:576px)',
}

export default Media
