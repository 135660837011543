import Web3 from 'web3'
import { MAIN_ZERO_ADDRESS, WBNB, ZERO_ADDRESS, DMC_CONTRACT_ADDRESS, DMC_POOL_ADDRESS } from '../constants/addresses'
import { DEX_TOOLS_NETWORK_NAMES, HTTP_PROVIDERS, NETWORK_NAMES } from '../constants'
import ERC20Abi from '../abi/ERC20.json'
import PairAbi from '../abi/pair.json'
import tokenPriceApi from './tokenPriceApi'

// export const getValueForTesting = async ({ amount, fromToken, toToken, router  , factoryContract , url = 'https://bsc-dataseed.binance.org', isBsc = true }) => {

//     // const web3Instance = new Web3(new Web3.providers.HttpProvider(url)).eth;

//     try{
//         const web3Instance = new Web3(new Web3.providers.HttpProvider(url)).eth;

//         const factories = new web3Instance.Contract(factoryAbi, factoryContract);
//         const pair = await factories.methods.getPair(fromToken, toToken).call();

//         if(ZERO_ADDRESS !== pair){
//             return (await getTokenValue(amount, fromToken, toToken, web3Instance, router));
//         }else{
//             const [_] = (await getTokenValue(amount, fromToken, isBsc ? WBNB : WETH, web3Instance, router));
//             const amtBC = await getTokenValue(_, isBsc ? WBNB : WETH, toToken, web3Instance, router);
//             return amtBC;
//         };
//     }catch(e){
//         return [0, 0];
//     };
// };

// const getTokenValue = async (amount, fromToken, toToken, web3Instance, router) => {
//     try{
//         const swaping = new web3Instance.Contract(uniswapAbi, router);
//         const decimals = await getDecimal(web3Instance, fromToken);
//         const realAmount = (fromExponential(amount * Math.pow(10, decimals)).split('.')[0]);
//         const amountOut = await swaping.methods.getAmountsOut(realAmount, [fromToken, toToken]).call();
//         const toDecimals = await getDecimal(web3Instance, toToken);
//         console.log((parseFloat(amountOut[1]) / (10 ** toDecimals)));
//         return [(parseFloat(amountOut[1]) / (10 ** toDecimals)), amountOut[1]];
//     }catch(e){
//         return 0;
//     }
// }

// export const getReservesRatio = async (from, pairAddress, url = 'https://bsc-dataseed.binance.org') => {
//     try{
//         const web3Instance = new Web3(new Web3.providers.HttpProvider(url)).eth;

//         const tokenPair = new web3Instance.Contract(pairAbi, pairAddress);
//         const token0 = await tokenPair.methods.token0().call();
//         const reserve = await tokenPair.methods.getReserves().call();
//         return String(token0).toLowerCase() === String(from).toLowerCase() ?  reserve._reserve0 / reserve._reserve1 : reserve._reserve1 / reserve._reserve0;
//     }catch(e){
//         return 0;
//     };
// };

// export const getImpactForTesting = async (from, to, fromAmt, toAmt, factory, url = 'https://bsc-dataseed.binance.org', isBsc = true) => {
//     if(!fromAmt || !toAmt) return 0;
//     let reserveRatio;
//     fromAmt = Web3Utils.toWei(String(fromAmt));
//     toAmt = Web3Utils.toWei(String(toAmt));
//     const swapFee = isBsc ? 0.25 : 0.3;
//     const pairAddress = await getPair(from, to, factory);
//     const r = await getReservesRatio(from, pairAddress);
//     if(pairAddress !== '0x0000000000000000000000000000000000000000') reserveRatio = (await getReservesRatio(from, pairAddress));
//     else{
//         const mediator = isBsc ? WBNB : WETH;
//         const pairAB = (await getPair(from, mediator, factory, url));
//         const pairBC = (await getPair(mediator, to, factory, url));
//         const ratioAB = (await getReservesRatio(from, pairAB, url));
//         const ratioBC = (await getReservesRatio(mediator, pairBC, url));
//         reserveRatio = ratioAB * ratioBC;
//     };
//     const amtRatio = fromAmt / toAmt;
//     const impact = (1 - (reserveRatio / amtRatio)) * 100 - swapFee;
//     return impact < 0 ?  0.00001 : impact;
// };

export const getDecimal = async (web3Instance, token) => {
  try {
    const instance = new web3Instance.Contract(ERC20Abi, token)
    const decimals = await instance.methods.decimals().call()
    return decimals
  } catch (e) {
    return 18
  }
}

export const smartPath = (W_Address, [a, b]) => {
  if (a === ZERO_ADDRESS || a === MAIN_ZERO_ADDRESS) {
    return [W_Address, b]
  } else if (b === ZERO_ADDRESS || b === MAIN_ZERO_ADDRESS) {
    return [a, W_Address]
  } else {
    return [a, b]
  }
}

/**
 *
 * @param {'eth' |  'bsc' | 'polygon' | 'ftm'} network
 * @param {string} pairToken
 * @returns
 */
export const generatePairExplorerUrl = (network, pairToken) => {
  const _network = DEX_TOOLS_NETWORK_NAMES[network]
  return `https://www.dextools.io/app/${_network || DEX_TOOLS_NETWORK_NAMES.eth}/pair-explorer/${String(pairToken)}`
}

/**
 * @description return dmc price in USD
 * @description only works in bsc
 * @description DMC only available in BSC for now. 2022-06
 * @returns
 */
export const getDMCPrice = async () => {
  const web3 = new Web3(HTTP_PROVIDERS[NETWORK_NAMES.bsc])
  let dmcBnbPoolContract = new web3.eth.Contract(PairAbi, DMC_POOL_ADDRESS)
  let WBNBContract = new web3.eth.Contract(ERC20Abi, WBNB)
  let DMCContract = new web3.eth.Contract(ERC20Abi, DMC_CONTRACT_ADDRESS)

  const bnbDecimals = await WBNBContract.methods.decimals().call()
  const dmcDecimals = await DMCContract.methods.decimals().call()
  const reserves = await dmcBnbPoolContract.methods.getReserves().call()
  const token0Address = await dmcBnbPoolContract.methods.token0().call()
  let inputAmount = 1 // 1 bnb
  let liquidityAmount = 0

  if (WBNB.toLowerCase() === token0Address.toLowerCase()) {
    inputAmount = inputAmount * 10 ** bnbDecimals
    let amountB = (inputAmount * reserves[1]) / reserves[0]
    liquidityAmount = amountB / 10 ** dmcDecimals
  } else {
    inputAmount = inputAmount * 10 ** dmcDecimals
    let amountB = (inputAmount * reserves[0]) / reserves[1]
    liquidityAmount = amountB / 10 ** bnbDecimals
  }
  const bnbPriceInUsd = await tokenPriceApi.calcBNBPrice()
  // console.log({
  //   reserves,
  //   bnbDecimals,
  //   dmcDecimals,
  //   'dmc': 1 / liquidityAmount * bnbPriceInUsd,
  //   liquidityAmount,
  //   bnbPriceInUsd
  // })
  return (1 / liquidityAmount) * bnbPriceInUsd
}

/**
 *
 * @param {String} _value @description Number in String
 * @description Remove zero in the front of String
 */
export const numberFormat = (_value = '') => {
  const checkAndRemoveZero = (v) => {
    if (v.length > 1 && v.startsWith('0') && v[1] !== '.') {
      v = v.substring(1)
      return checkAndRemoveZero(v)
    }
    return v
  }
  let value = _value
  if (isNaN(value)) {
    value = ''
  }
  value = checkAndRemoveZero(value)
  return value
}
