import { useCallback, useEffect, useState } from 'react'
import { LOCAL_STORAGE_ITEM_NAMES } from '../constants/localStorage'

export function useCommonTokens() {
  const [commonBases, setCommonBases] = useState(() => {
    let savedItems = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS)
    if (savedItems) {
      savedItems = JSON.parse(savedItems)
      try {
        savedItems = [...savedItems]
        return savedItems
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS)
        return []
      }
    } else {
      return []
    }
  }) // Array of Token Address String

  const removeDuplicate = useCallback(
    /**
     *
     * @param {Array} values
     */
    (values) => {
      return values.filter((v, index, self) => self.findIndex((sV) => sV === v) === index)
    },
    []
  )

  const add = useCallback(
    (_address) => {
      if (Array.isArray(_address)) {
        setCommonBases((p) => {
          let newValues = [...p, ..._address]
          return removeDuplicate(newValues)
        })
      } else if (typeof _address === 'string') {
        setCommonBases((p) => {
          let newValues = [...p, _address]
          return removeDuplicate(newValues)
        })
      }
    },
    [removeDuplicate]
  )

  const remove = useCallback((_address) => {
    setCommonBases((p) => p.filter((v) => String(v).toLowerCase() !== String(_address).toLowerCase()))
  }, [])

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS, JSON.stringify(commonBases))
  }, [commonBases])

  return { commonBases, add, remove }
}
